import { useQuery } from "@apollo/client"
import { QUERY_FETCH_COMMITTEE } from "../../graphql/queries"

const useCommittee = (CMID) => {
  const { data, loading, refetch } = useQuery(QUERY_FETCH_COMMITTEE, {
    variables: {
      CMID,
    },
    fetchPolicy: "network-only",
    skip: !CMID,
  })

  const committee = (data && data.committee) || {}
  const conference = (data && data.committee && data.committee.conference) || {}

  return { committee, conference, loading, refetch }
}

export default useCommittee
