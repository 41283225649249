import React, { useContext } from "react"
import CommitteeContext from "../../contexts/CommitteeContext"

function CommitteeName() {
  const { committeeContext } = useContext(CommitteeContext)

  return (
    <h1 className="text-xl font-bold text-black">
      {(committeeContext && committeeContext.name) || "N/A"}
    </h1>
  )
}

export default CommitteeName
