import React from "react"
import ReactCountryFlag from "react-country-flag"

function RoleOption({ role, name }) {
  return (
    // props.role.detail.representative.code
    <div className="h-8 hover:text-blue-600">
      <div className="inline-block">
        {
          role && role.detail && role.detail.representative
            && role.detail.representative.code ?
            <ReactCountryFlag
              countryCode={role.detail.representative.code}
              svg
              style={{
                width: "1em",
                height: "1em",
              }}
            /> : null
        }
      </div>
      <div className="inline-block">
        <h3 className="ml-3">
          {name.substring(0, 21) + (name.length > 21 ? "..." : "")}
        </h3>
      </div>
    </div>
  )
}

export default RoleOption
