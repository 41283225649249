/* eslint-disable no-underscore-dangle */
import React, { useMemo, useContext } from "react"

import { useParams } from "react-router-dom"
import ConferenceName from "../ConferenceItems/ConferenceName"
import ConferenceStatus from "../ConferenceItems/ConferenceStatus"
import CommitteeStatus from "../CommitteeItems/CommitteeStatus"
import CommitteeName from "../CommitteeItems/CommitteeName"
import CommitteeDetail from "../CommitteeItems/CommitteeDetail"
import ResoCard from "../../common/Cards/ResoCard"
import AnnouncementCard from "../../common/Cards/AnnouncementCard"
import TallySheetCard from "../../common/Cards/TallySheetCard"

import useConference from "../../hooks/useConference"
import ConferenceItems from "../ConferenceItems"
import CommitteeItems from "../CommitteeItems"
import CollapseContext from "../../contexts/CollapseContext"

function FixedMenu() {
  const { confId, committeeId } = useParams()
  const {
    conferenceMenuCloseContext,
    setConferenceMenuCloseContext,
  } = useContext(CollapseContext)
  const { committees, conference } = useConference(confId)

  const currentCommittee = useMemo(() => {
    if (committeeId && committees) {
      return committees.find(({ _id }) => _id === committeeId)
    }

    return {}
  }, [committeeId, committees])

  return (
    <>
      <>
        <div className="hidden md:col-span-2 md:bg-blue-600 md:flex md:flex-col md:justify-between md:p-3">
          <div className="w-full mt-3">
            <ConferenceName conference={conference} />
            <ConferenceStatus conference={conference} />
            <ConferenceItems confId={confId} />
          </div>
          <button
            type="button"
            className="text-gray-500 fill-current animate__bounceIn focus:outline-none hover:text-white"
            onClick={() => {
              setConferenceMenuCloseContext(!conferenceMenuCloseContext)
            }}
          >
            {conferenceMenuCloseContext ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className="w-6 h-6"
              >
                <path d="M10 0a10 10 0 110 20 10 10 0 010-20zM2 10a8 8 0 1016 0 8 8 0 00-16 0zm10.54.7L9 14.25l-1.41-1.41L10.4 10 7.6 7.17 9 5.76 13.24 10l-.7.7z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className="w-6 h-6"
              >
                <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm8-10a8 8 0 1 0-16 0 8 8 0 0 0 16 0zM7.46 9.3L11 5.75l1.41 1.41L9.6 10l2.82 2.83L11 14.24 6.76 10l.7-.7z" />
              </svg>
            )}
          </button>
        </div>

        <div className="hidden md:flex md:col-span-2 md:bg-white md:p-3 md:pt-3">
          <div className="w-full mt-3">
            <CommitteeName committee={currentCommittee} />
            <CommitteeStatus committee={currentCommittee} />
            <div className="w-full mt-12 rounded">
              <CommitteeItems />
            </div>
          </div>
        </div>
      </>

      <div
        className={`col-span-12 bg-gray-200 p-3 pt-3 md:${
          conferenceMenuCloseContext ? "col-span-8" : "col-span-6"
        }`}
      >
        <div className="mt-2 mb-10">
          <CommitteeDetail committee={currentCommittee} />
        </div>

        <div className="flex flex-wrap">
          <div className="w-full p-2 pr-2 mb-1 md:w-1/2">
            <ResoCard />
          </div>
          <div className="w-full p-2 pl-2 mb-1 md:w-1/2">
            <AnnouncementCard />
          </div>
        </div>

        <div className="flex">
          <div className="w-full p-2 pt-2">
            <TallySheetCard />
          </div>
        </div>
      </div>
    </>
  )
}

export default FixedMenu
