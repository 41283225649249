/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Modal from "./index"
import useCommittee from "../../hooks/useCommittee"
import { itemStatusCode, committeeRuleOfProcedure } from "../../../constant"
import Spinner from "../Spinners/Spinner1"

function EditCommitteeModal({ onSubmit, close, isShown, targetID }) {
  const { committee, loading } = useCommittee(targetID)

  const [name, setName] = useState("")
  const [status, setStatus] = useState("")
  const [type, setType] = useState("")

  // Fetch Current Committee Data
  useEffect(() => {
    setName(committee.name)
    setStatus(committee.status)
    setType(committee.type)
  }, [committee])

  return (
    <>
      <Modal
        close={close}
        isShown={isShown}
        onSubmit={() => {
          const data = {
            CMID: committee._id,
            name,
            status,
            type,
          }

          onSubmit(data).then(() => {
            setName("")
            setStatus("")
            setType("")
            close()
          })
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-committee-name"
                >
                  Committee Name
                </label>
                <input
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-committee-name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="w-full px-3 md:w-1/2">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-status"
                >
                  Status
                </label>
                <div className="relative">
                  <select
                    className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={itemStatusCode.Active}>Active</option>
                    <option value={itemStatusCode.Inactive}>Inactive</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-rop"
                >
                  Rule of Procedure
                </label>
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-rop"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value={committeeRuleOfProcedure.THIMUN}>
                    THIMUN
                  </option>
                  <option value={committeeRuleOfProcedure.UNAUSA}>
                    UNAUSA
                  </option>
                </select>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

EditCommitteeModal.propTypes = {
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default EditCommitteeModal
