/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useMemo, useEffect, useContext } from "react"
import { useMutation } from "@apollo/client"
import jwt_decode from "jwt-decode"
import useDirectors from "../../hooks/useDirectors"
import useConference from "../../hooks/useConference"
import { tokenField, roleField } from "../../../constant"
import { MUTATION_ROLE_LOGIN } from "../../../graphql/mutations"
import ConferenceContext from "../../contexts/ConferenceContex"
import { useHistory } from "react-router"

function getDirectorConfName(director) {
  return (director.roleTarget && director.roleTarget.name) || null;
}

function RoleSwitch() {
  const user = localStorage.getItem(tokenField)
    ? jwt_decode(localStorage.getItem(tokenField))
    : null;

  const [isShow, setShow] = useState(false)

  const [roleLogin] = useMutation(MUTATION_ROLE_LOGIN)
  const { directors } = useDirectors(user && user._id)

  const history = useHistory();

  useEffect(() => {
    if (directors && directors.length > 0 && !selectedDirectorId) {
      setSelectedDirectorId(directors[0]._id);
    }
  }, [directors]);

  const [selectedDirectorId, setSelectedDirectorId] = useState(null);

  const index = useMemo(() => {
    if (directors) {
      return directors.findIndex(({ _id }) => _id === selectedDirectorId);
    }

    return -1;
  }, [directors, selectedDirectorId]);

  const { conference } = useConference(
    (directors &&
      directors[index] &&
      directors[index].roleTarget &&
      directors[index].roleTarget._id) ||
    null
  );

  const { setConferenceContext } = useContext(ConferenceContext);
  useEffect(() => {
    if (conference && conference._id) {
      setConferenceContext(conference);
      history.replace(`/conference/${conference._id}`);
    }
  }, [conference, history, setConferenceContext]);

  useEffect(() => {
    if (selectedDirectorId) {
      roleLogin({
        variables: {
          RID: selectedDirectorId,
        }
      }).then(({ data }) => {
        localStorage.setItem(roleField, data.roleLogin.token);
      });
    }
  }, [selectedDirectorId, roleLogin]);

  return (
    <div className="relative w-full">
      <div
        className={`${isShow ? "" : "hidden"} fixed w-screen h-screen top-0`}
        onClick={() => setShow(false)}
      />
      <h3 className="w-full h-16 text-xs font-light text-white">
        <div className="relative inline-block w-full text-left">
          <button
            type="button"
            className="inline-flex justify-start w-full py-0 text-sm text-white bg-transparent rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setShow(true)}
          >
            <font className="w-full text-left">{directors && index !== -1 && (getDirectorConfName(directors[index]) || "N/A")}</font>

            <svg
              className="self-auto float-right w-5 h-5 ml-2 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </h3>
      <div
        className={`absolute top-0 left-0 w-48 mt-6 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black
           ${isShow ? "" : "hidden"}`}
      >
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {directors && directors.map((director, i) => (
            <span
              className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              key={i}
              onClick={() => {
                setSelectedDirectorId(director._id)
                setShow(false)
              }}
            >
              {getDirectorConfName(director)}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RoleSwitch
