import React from "react"
import { Route, Redirect } from "react-router-dom"
import { tokenField } from "../../constant"

function PrivateRoute({ component: Component, restricted, ...rest }) {
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page

  const isLogin = localStorage.getItem(tokenField) !== null

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLogin ? <Component {...props} /> : <Redirect to="/login/email" />
      }
    />
  )
}

export default PrivateRoute
