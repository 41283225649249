/* eslint-disable camelcase */
import React from "react"
import ReactDOM from "react-dom"
import "./styles/app.css"
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { setContext } from "@apollo/client/link/context"
import i18next from "i18next"
import { I18nextProvider } from "react-i18next"
import App from "./App"
import { tokenField, roleField } from "./constant"

import common_en_US from "./translations/en-US.json"
import common_zh_TW from "./translations/zh-TW.json"

const authLink = setContext((_, prevContext) => {
  const token = localStorage.getItem(tokenField)
    ? localStorage.getItem(tokenField)
    : ""
  const rToken = localStorage.getItem(roleField)
    ? localStorage.getItem(roleField)
    : ""

  const { headers } = prevContext;

  if (prevContext && prevContext.noAuthHeader) {
    return prevContext;
  }

  return {
      ...prevContext,
      headers: {
        ...headers,
        authorization: token,
        role: rToken,
      },
    }
})

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_API_URL,
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    }
  },
})

i18next.init({
  load: "languageOnly",
  lng: window.localStorage.getItem("i18nextLng")
    ? window.localStorage.getItem("i18nextLng")
    : "zh_TW",
  debug: true,
  interpolation: { escapeValue: false },
  fallbackLng: "en",
  defaultNS: "common",
  resources: {
    en: {
      common: common_en_US,
    },
    zh_TW: {
      common: common_zh_TW,
    },
  },
  react: {
    wait: true,
    useSuspense: false,
  },
})

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>
  </I18nextProvider>,
  document.getElementById("root")
)
