/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import PropTypes from "prop-types"

function EditIcon({ color, hoverColor }) {
  return (
    <a href="#" className={`${color} fill-current hover:${hoverColor}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        className="w-3 h-3"
      >
        <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
      </svg>
    </a>
  )
}

EditIcon.propTypes = {
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
}

export default EditIcon
