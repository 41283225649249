import React from "react"

function AnnouncementCard() {
  return (
    <div className="rounded shadow-lg bg-white h-64">
      <div className="px-6 py-4">
        <h3 className="text-black font-bold text-sm">Announcement</h3>
        <div className="mt-2">
          <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ">
            <option>Alert</option>
            <option>Announcement</option>
          </select>
        </div>

        <div className="mt-2">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
            id="title"
            type="text"
            placeholder="Enter Title"
          />
        </div>

        <div className="mt-2">
          <textarea
            className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
            id="title"
            type="text"
            placeholder="Enter Content"
          />
        </div>

        <div className="mt-2 w-full">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementCard
