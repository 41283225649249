/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from "./index"
import { committeeRuleOfProcedure } from "../../../constant"

function AddCommitteeModal({ close, isShown, onSubmit }) {
  const [nameEmptyError, setNameEmptyError] = useState(false)
  const [typeEmptyError, setTypeEmptyError] = useState(false)

  const [name, setName] = useState("")
  const [type, setType] = useState("")

  return (
    <>
      <Modal
        close={close}
        isShown={isShown}
        onSubmit={() => {
          const data = {
            name,
            type,
          }

          if (name.trim().length === 0) {
            setNameEmptyError(true)
            return
          }
          setNameEmptyError(false)

          if (type.trim().length === 0) {
            setTypeEmptyError(true)
            return
          }
          setTypeEmptyError(false)

          onSubmit(data).then(() => {
            setNameEmptyError(false)
            setNameEmptyError(false)
            setName("")
            setType("")
            close()
          })
        }}
      >
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-committee-name"
            >
              Committee Name
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                nameEmptyError ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
              id="grid-committee-name"
              type="text"
              placeholder="GA1"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameEmptyError ? (
              <p className="text-xs italic text-red-500">
                Name cannot be empty!
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-rop"
            >
              Rule of Procedure
            </label>
            <select
              className={`block appearance-none w-full bg-gray-200 border${
                typeEmptyError ? "border-red-500" : "border-gray-200"
              } text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-rop"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">-</option>
              <option value={committeeRuleOfProcedure.THIMUN}>THIMUN</option>
              <option value={committeeRuleOfProcedure.UNAUSA}>UNAUSA</option>
            </select>
            {typeEmptyError ? (
              <p className="text-xs italic text-red-500">
                Rule of procedure cannot be empty!
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

AddCommitteeModal.propTypes = {
  close: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AddCommitteeModal
