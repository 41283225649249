import React, { useContext } from "react"
import ConferenceContext from "../../contexts/ConferenceContex"

function ConferenceName() {
  const { conferenceContext } = useContext(ConferenceContext)

  return (
    <h1 className="text-xl font-bold text-white">
      {(conferenceContext && conferenceContext.name) || "N/A"}
    </h1>
  )
}

export default ConferenceName
