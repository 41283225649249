import { useQuery } from "@apollo/client"
import { useMemo } from "react"
import { QUERY_FETCH_COMMITTEE_ROLES } from "../../graphql/queries"
import { roleCode } from "../../constant"

const useCommitteeRoles = (CMID) => {
  const { data, loading, refetch } = useQuery(QUERY_FETCH_COMMITTEE_ROLES, {
    variables: {
      CMID,
    },
    fetchPolicy: "network-only",
    skip: !CMID,
  })

  const roles = (data && data.committee && data.committee.roles) || []

  const { delegates, admins, chairs } = useMemo(() => {
    if (roles) {
      return {
        delegates: roles.filter((v) => v && v.role === roleCode.Delegate),
        chairs: roles.filter((v) => v && v.role === roleCode.Chair),
        admins: roles.filter((v) => v && v.role === roleCode.Admin),
      }
    }

    return {
      delegates: [],
      chairs: [],
      admins: [],
    }
  }, [roles])

  return { roles, loading, delegates, admins, chairs, refetch }
}

export default useCommitteeRoles
