/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from "react"
import { Transition } from "@tailwindui/react"
import { useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import CommitteeOption from "../../common/Options/CommitteeOption"
import AddOption from "../../common/Options/AddOption"
import EditCommitteeModal from "../../common/Modals/EditCommitteeModal"
import AddCommitteeModal from "../../common/Modals/AddCommitteeModal"
import useCommittees from "../../hooks/useCommittees"
import CommitteeContext from "../../contexts/CommitteeContext"
import ConferenceContext from "../../contexts/ConferenceContex"

import {
  MUTATION_EDIT_COMMITTEE,
  MUTATION_ADD_COMMITTEE,
} from "../../../graphql/mutations"

function ConferenceItems({ confId }) {
  const { conferenceContext } = useContext(ConferenceContext)

  const { committees, refetch } = useCommittees(
    conferenceContext && conferenceContext._id
  )

  const { setCommitteeContext } = useContext(CommitteeContext)

  const [openedModal, setOpenedModal] = useState({
    addIsShown: false,
    editIsShown: false,
    targetID: "",
    onSubmitFunc: () => {},
  })

  const history = useHistory()

  const closeModal = () => {
    setOpenedModal({
      editIsShown: false,
      addIsShown: false,
      onSubmitFunc: () => {},
    })
  }

  const [editCommittee] = useMutation(MUTATION_EDIT_COMMITTEE, {
    onCompleted: () => {
      refetch()
    },
  })
  const [addCommittee] = useMutation(MUTATION_ADD_COMMITTEE, {
    onCompleted: () => {
      refetch()
    },
  })

  const onEditCommitteeSubmit = (data) => {
    return editCommittee({
      variables: {
        CMID: data.CMID,
        name: data.name,
        status: data.status,
        type: data.type,
      },
    })
  }

  const onAddCommitteeSubmit = (data) => {
    return addCommittee({
      variables: {
        name: data.name,
        type: data.type,
        CFID: conferenceContext && conferenceContext._id,
      },
    })
  }

  return (
    <>
      <div className="pt-8">
        <Transition show={!!conferenceContext._id}>
          <AddOption
            type="Committee"
            iconColor="text-white"
            hoverIconColor="text-gray-800"
            onClick={() => {
              setOpenedModal({
                addIsShown: true,
                onSubmitFunc: onAddCommitteeSubmit,
              })
            }}
          />
        </Transition>
      </div>
      <div className="overflow-auto h-128">
        {committees.map((committee) => (
          <div
            key={committee._id}
            onClick={() => {
              history.push(
                `/conference/${conferenceContext && conferenceContext._id}/${
                  committee._id
                }`
              )
              setCommitteeContext(committee)
            }}
          >
            <CommitteeOption
              committee={committee}
              iconColor="text-white"
              hoverIconColor="text-gray-800"
              onClick={() => {
                setOpenedModal({
                  editIsShown: true,
                  onSubmitFunc: onEditCommitteeSubmit,
                  targetID: committee._id,
                })
              }}
            />
          </div>
        ))}
        <EditCommitteeModal
          onSubmit={openedModal.onSubmitFunc}
          close={closeModal}
          isShown={openedModal.editIsShown}
          targetID={openedModal.targetID}
        />
        <AddCommitteeModal
          onSubmit={openedModal.onSubmitFunc}
          close={closeModal}
          isShown={openedModal.addIsShown}
        />
      </div>
    </>
  )
}

ConferenceItems.defaultProps = {
  confId: "",
}

ConferenceItems.propTypes = {
  confId: PropTypes.string,
}

export default ConferenceItems
