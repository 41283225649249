import React from "react"
import PropTypes from "prop-types"

function TalleySheetOption({
  name,
  poi,
  speeches,
  amendments,
  questions,
  status,
}) {
  return (
    <div className="flex h-8">
      <div className="w-3/12">
        <h3>{name}</h3>
      </div>
      <div className="w-1/12">
        <h3>{poi}</h3>
      </div>
      <div className="w-2/12 h-2">
        <h3>{speeches}</h3>
      </div>
      <div className="w-2/12 h-2">
        <h3>{amendments}</h3>
      </div>
      <div className="w-2/12 h-2">
        <h3>{questions}</h3>
      </div>
      <div className="w-2/12 h-2">
        <h3 className="font-bold text-green-600">{status}</h3>
      </div>
    </div>
  )
}

TalleySheetOption.defaultProps = {
  name: "",
  poi: 0,
  speeches: 0,
  amendments: 0,
  questions: 0,
  status: "",
}

TalleySheetOption.propTypes = {
  name: PropTypes.string,
  poi: PropTypes.number,
  speeches: PropTypes.number,
  amendments: PropTypes.number,
  questions: PropTypes.number,
  status: PropTypes.string,
}

export default TalleySheetOption
