import React, { useMemo } from "react"
import { Route, Redirect } from "react-router-dom"
import jwt_decode from "jwt-decode"
import { tokenField } from "../../constant"
import useDirector from "../hooks/useDirectors"

function PublicRoute({ component: Component, restricted, ...rest }) {
  const user = useMemo(
    () =>
      localStorage.getItem(tokenField)
        ? jwt_decode(localStorage.getItem(tokenField))
        : null,
    []
  )

  const { directors, loading } = useDirector((user && user._id) || "")
  const isLogin =
    localStorage.getItem(tokenField) !== null &&
    !loading &&
    directors &&
    directors.length !== 0

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLogin && restricted ? (
          <Redirect to="/login/email" />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default PublicRoute
