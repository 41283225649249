import React, { useContext } from "react"
import OrganizationContext from "../../contexts/OrganizationContext"

function OrganizationName() {
  const { organizationContext } = useContext(OrganizationContext)

  return (
    <h1 className="text-xl font-bold text-white">
      {organizationContext.name || "N/A"}
    </h1>
  )
}

export default OrganizationName
