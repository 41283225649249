/* eslint-disable no-underscore-dangle */
import React from "react"
import ReactCountryFlag from "react-country-flag"
import PropTypes from "prop-types"
import Modal from "./index"
import { roleDetailType } from '../../prop-types'

function ViewLoginCodeModal({ data, isShown, close }) {
  const countries = Array.isArray(data) ? data : []

  return (
    <>
      <Modal close={close} isShown={isShown} hideSubmitButton>
        <div className="flex flex-wrap mb-6 -mx-3 overflow-y-scroll h-128">
          {countries
            .sort((a, b) => {
              return a.detail.representative.name > b.detail.representative.name
                ? 1
                : -1
            })
            .map((country) => {
              return (
                <div
                  className="flex-col w-full max-w-sm lg:max-w-full lg:flex"
                  key={country._id}
                >
                  <div className="box-border flex flex-col justify-between p-4 leading-normal bg-white border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400">
                    <div className="mb-8">
                      <div className="mb-2 text-xl font-bold text-gray-900">
                        {country.detail.representative.name}
                      </div>
                      <div className="flex flex-row">
                        <ReactCountryFlag
                          countryCode={
                            country.detail.representative.code
                              ? country.detail.representative.code
                              : "US"
                          }
                          svg
                          style={{
                            width: "4em",
                            height: "4em",
                          }}
                        />
                        <p className="px-4 font-bold">
                          {country &&
                            country.detail &&
                            country.detail.loginCode}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </Modal>
    </>
  )
}

ViewLoginCodeModal.propTypes = {
  close: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  data: roleDetailType.isRequired,
}

export default ViewLoginCodeModal
