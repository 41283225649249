import React from "react"
import TalleySheetOption from "./TalleySheetOption"

function TallySheetCard() {
  return (
    <div className="rounded shadow-lg bg-white relative h-64">
      <div className="px-6 py-4">
        <h3 className="text-black font-bold text-sm pb-2">
          Tally Sheet / Roll Call
        </h3>
        <div className="text-gray-500 font-bold text-xs pb-2">
          P: POI | S: Speeches | A: Amendments | Q: Questions
        </div>
        <div className="flex font-light text-gray-500 text-sm pb-8">
          <div className="w-3/12 h-2">Delegate</div>
          <div className="w-1/12 h-2">P</div>
          <div className="w-2/12 h-2">S</div>
          <div className="w-2/12 h-2">A</div>
          <div className="w-2/12 h-2">Q</div>
          <div className="w-2/12 h-2">Status</div>
        </div>
        <div className="grid-flow-row overflow-y-scroll h-32 text-sm font-light">
          <TalleySheetOption
            name="Alex Zen"
            poi={3}
            amendments={6}
            speeches={4}
            questions={5}
            status="Present"
          />
          <TalleySheetOption
            name="Alex Zen"
            poi={3}
            amendments={6}
            speeches={4}
            questions={5}
            status="Present"
          />
          <TalleySheetOption
            name="Alex Zen"
            poi={3}
            amendments={6}
            speeches={4}
            questions={5}
            status="Present"
          />
          <TalleySheetOption
            name="Alex Zen"
            poi={3}
            amendments={6}
            speeches={4}
            questions={5}
            status="Present"
          />
          <TalleySheetOption
            name="Alex Zen"
            poi={3}
            amendments={6}
            speeches={4}
            questions={5}
            status="Present"
          />
          <TalleySheetOption
            name="Alex Zen"
            poi={3}
            amendments={6}
            speeches={4}
            questions={5}
            status="Present"
          />
        </div>
      </div>
    </div>
  )
}

export default TallySheetCard
