import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Modal from "./index"

function AddConferenceModal({ close, isShown, onSubmit }) {
  const { t } = useTranslation()

  const [startDateEndDateError, setstartDateEndDateError] = useState(false)

  const [ORGID] = useState("")
  const [name, setName] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [logoSrc, setLogoSrc] = useState("")

  return (
    <>
      <Modal
        close={close}
        isShown={isShown}
        onSubmit={() => {
          const data = {
            ORGID,
            name,
            startTime,
            endTime,
            logoSrc,
          }

          if (startTime > endTime) {
            setstartDateEndDateError(true)
            return
          }

          onSubmit(data).then(() => {
            setName("")
            setStartTime("")
            setEndTime("")
            setLogoSrc("")
            setstartDateEndDateError(false)
            close()
          })
        }}
      >
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-name"
            >
              {t("dashboard.organizations.modal.addConference.conferenceName")}
            </label>
            <input
              className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-startTime"
              type="text"
              placeholder="THIMUN"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-startTime"
            >
              {t(
                "dashboard.organizations.modal.addConference.conferenceStartTime"
              )}
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                startDateEndDateError ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-startTime"
              type="datetime-local"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-endTime"
            >
              {t(
                "dashboard.organizations.modal.addConference.conferenceEndTime"
              )}
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                startDateEndDateError ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-endTime"
              type="datetime-local"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
            {startDateEndDateError ? (
              <p className="text-xs italic text-red-500">
                {t(
                  "dashboard.organizations.modal.error.startEndTimeValidationError"
                )}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 ">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-icon"
            >
              {t("dashboard.organizations.modal.addConference.conferenceLogo")}
            </label>
            <input
              className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-icon"
              type="text"
              placeholder="https://munative.com/logo.ico"
              value={logoSrc}
              onChange={(e) => setLogoSrc(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 ">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-icon"
            >
              {t("dashboard.organizations.modal.addConference.logoPreview")}
            </label>
            <img alt="Not Found" width="40" height="40" src={logoSrc} />
          </div>
        </div>
      </Modal>
    </>
  )
}

AddConferenceModal.propTypes = {
  close: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AddConferenceModal
