import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import AnimatedButton from "../common/Buttons/animatedButton"

const Code = ({ tailwind }) => {
  return (
    <div className={`${tailwind} flex h-screen bg-blue-primary`}>
      <div className="w-full flex flex-col justify-center md:bg-white">
        <div className="m-auto flex-col justify-center text-center">
          <h1 className="text-5xl font-bold text-white md:text-blue-primary">
            Code
          </h1>
          <p className=" text-base text-white md:text-blue-primary">
            for chairs and directors.
          </p>
          <input
            type="text"
            className="block focus:outline-none text-sm w-70  mt-12 bg-white md:bg-gray-light placeholder-gray-dark py-3 px-4 rounded-full md:rounded-xlg"
            placeholder="Code"
          />
          <AnimatedButton
            activeState="true"
            tailwind="border-none text-sm w-1/2 h-10 text-blue-primary md:text-white bg-white md:bg-blue-primary mt-6 rounded-full md:rounded-xlg"
            text="Login"
          />
        </div>
        <div className="w-full flex justify-center mb-6">
          <div className=" text-center">
            <a
              href="#1"
              className="  text-white md:text-blue-primary text-sm block"
            >
              Sign Up Here!
            </a>
            <a
              href="#2"
              className="text-white md:text-blue-primary text-sm mt-0-1 block"
            >
              Click me if you forgot your code?
            </a>
          </div>
        </div>
      </div>
      <div className="md:flex w-full flex flex-col justify-center">
        <div className="m-auto flex-col justify-center text-center	">
          <h1 className="text-5xl font-bold text-white">Welcome Back!</h1>
          <p className=" text-base text-white">
            Are you ready to add another conference to your <br />
            everlasting MUN journey?
          </p>
          <p className="text-sm text-white mt-36">
            Have a code email instead? Try this one!
          </p>
          <Link to="/login/email">
            <AnimatedButton
              tailwind="w-44 text-sm h-10 text-white border-gray-100 mt-3 rounded-xlg"
              text="Login with Email"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

Code.propTypes = {
  tailwind: PropTypes.string.isRequired,
}

export default Code
