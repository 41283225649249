import gql from "graphql-tag"

export const MUTATION_LOGIN = gql`
  mutation loginMutation($email: String!, $password: String!) {
    userLogin(input: { email: $email, password: $password }) {
      token
    }
  }
`

export const MUTATION_ROLE_LOGIN = gql`
  mutation roleLoginMutation($RID: ID!) {
    roleLogin(input: { RID: $RID }) {
      token
    }
  }
`

export const MUTATION_UPDATE_PASSWORD = gql`
  mutation updatePasswordMutation($USID: ID!, $password: String!) {
    updatePassword(input: { USID: $USID, password: $password }) {
      user {
        _id
      }
    }
  }
`

export const MUTATION_ADD_ROLE = gql`
  mutation addRoleMutation(
    $USID: ID
    $role: RoleType!
    $roleTarget: ID
    $roleTargetType: String
    $detail: JSON
  ) {
    createRole(
      input: {
        USID: $USID
        role: $role
        roleTarget: $roleTarget
        roleTargetType: $roleTargetType
        detail: $detail
      }
    ) {
      role {
        _id
      }
    }
  }
`

export const MUTATION_EDIT_ROLE = gql`
  mutation editRoleMutation(
    $RID: ID!
    $USID: ID
    $role: RoleType
    $roleTarget: ID
    $roleTargetType: String
    $detail: JSON
    $status: ItemStatusCode
  ) {
    editRole(
      input: {
        RID: $RID
        USID: $USID
        role: $role
        roleTarget: $roleTarget
        roleTargetType: $roleTargetType
        detail: $detail
        status: $status
      }
    ) {
      role {
        _id
      }
    }
  }
`

export const MUTATION_ADD_CONFERENCE = gql`
  mutation addConferenceMutation(
    $ORGID: ID!
    $name: String!
    $startTime: DateTime
    $endTime: DateTime
    $logoSrc: String
  ) {
    createConference(
      input: {
        ORGID: $ORGID
        name: $name
        startTime: $startTime
        endTime: $endTime
        logoSrc: $logoSrc
      }
    ) {
      conference {
        _id
      }
    }
  }
`

export const MUTATION_EDIT_CONFERENCE = gql`
  mutation editConferenceMutation(
    $CFID: ID!
    $ORGID: ID
    $name: String
    $startTime: DateTime
    $endTime: DateTime
    $logoSrc: String
    $status: ItemStatusCode
  ) {
    editConference(
      input: {
        CFID: $CFID
        ORGID: $ORGID
        name: $name
        startTime: $startTime
        endTime: $endTime
        logoSrc: $logoSrc
        status: $status
      }
    ) {
      conference {
        _id
      }
    }
  }
`

export const MUTATION_ADD_COMMITTEE = gql`
  mutation addCommitteeMutation(
    $name: String!
    $type: CommitteeType!
    $CFID: ID!
  ) {
    createCommittee(input: { name: $name, type: $type, CFID: $CFID }) {
      committee {
        _id
      }
    }
  }
`

export const MUTATION_EDIT_COMMITTEE = gql`
  mutation editCommitteeMutation(
    $CMID: ID!
    $name: String
    $type: CommitteeType
    $status: ItemStatusCode
  ) {
    editCommittee(
      input: { CMID: $CMID, name: $name, type: $type, status: $status }
    ) {
      committee {
        name
        options
      }
    }
  }
`

export const MUTATION_UPDATE_COMMITTEE_OPTIONS = gql`
  mutation updateCommitteeOptionsMutation($CMID: ID!, $options: JSON!) {
    updateCommitteeOptions(input: { CMID: $CMID, options: $options }) {
      committee {
        name
        options
      }
    }
  }
`

export const MUTATION_POST_ANNOUNCEMENT = gql`
  mutation postAnnouncementMutation(
    $target: String!
    $targetID: ID!
    $content: String!
  ) {
    postAnnouncement(
      input: { target: $target, targetID: $targetID, content: $content }
    ) {
      announcement {
        _id
        content
        postTime
      }
    }
  }
`

export const MUTATION_DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncementMutation($ANID: ID!) {
    deleteAnnouncement(input: { ANID: $ANID }) {
      announcement {
        _id
      }
    }
  }
`

export const MUTATION_REGENERATE_LOGINCODE = gql`
  mutation regenerateLoginCode($RID: ID!) {
    resetLoginCode(input: { RID: $RID }) {
      role {
        _id
        detail
      }
    }
  }
`

export const MUTATION_CHAIR_INVITE = gql`
  mutation chairInvite($email: String!, $CMID: ID!, $firstName: String, $lastName: String){
    chairInvite(input: {email: $email, CMID: $CMID, firstName: $firstName, lastName: $lastName}){
      user {
        _id
      }
      role{
        _id
      }
    }
  }

`