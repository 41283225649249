import React from "react"
import { useTranslation } from "react-i18next"

function RoleDivider(props) {
  const { t } = useTranslation()

  const renderSwitch = (input) => {
    switch (input) {
      case "Chairs":
        return t("dashboard.committees.chairs")
      case "Admin Staffs":
        return t("dashboard.committees.admins")
      case "Delegates":
        return t("dashboard.committees.delegates")
      default:
        return ""
    }
  }

  return (
    <h3 className={`font-light ${props.color} text-xs my-2`}>
      {renderSwitch(props.type)} - {props.number}
    </h3>
  )
}

export default RoleDivider
