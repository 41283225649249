export const i18nextLngField = "i18nextLng"
export const apolloCacheField = "apollo-cache-persist"
export const tokenField = "authorization"
export const roleField = "role"
export const currentRole = "curRole"
export const currentChat = "curChatID"
export const munativePic =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4xLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMDAwIDI0ODAuMjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwMDAgMjQ4MC4yNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6IzFCMkYzRjt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE4ODQuNDMsMTE1Mi44MWwtMTA2LjY5LTI1NC4yNUgwbDY2My43MiwxNTgxLjdoMTc3Ny43M2gxLjUxTDMwMDAsMTE1Mi44MUgxODg0LjQzeiBNODM2LjY4LDIyMjYuMDENCgkJbC00NTAuMzUtMTA3My4yaDEyMjIuNTJMMjA1OS4xOSwyMjI2SDgzNi42OFYyMjI2LjAxeiIvPg0KCTxjaXJjbGUgY2xhc3M9InN0MCIgY3g9IjIzMDYuNjgiIGN5PSIzMzEuMTgiIHI9IjMzMS4xOCIvPg0KPC9nPg0KPC9zdmc+DQo="
export const roleCode = {
  /** DE is delegate */
  Delegate: "Delegate",
  /** D is director */
  Director: "Director",
  /** C is chair */
  Chair: "Chair",
  /** A is admin */
  Admin: "Admin",
  /** SA is system admin */
  SystemAdmin: "SystemAdmin",
  NoAuth: "NOAUTH",
  NoRole: "NOROLE",
}

export const type = {
  Organization: "Organization",
  Conference: "Conference",
  Committee: "Committee",
}

export const committeeRuleOfProcedure = {
  THIMUN: "THIMUN",
  UNAUSA: "UNUSA",
}

export const CMStatus = {
  None: -1,
  RollCall: 1,
  SpeakersList: 2,
  Vote: 3,
}
export const rightDisplayMode = {
  None: -1,
  resolutionOrAmendments: 1,
  Timer: 2,
  CustomText: 3,
}
export const attendStatusCode = {
  Absent: 0,
  Present: 1,
  PresentAndVoting: 2,
}
export const resolutionStatusCode = {
  Pending: "Pending",
  Passed: "Passed",
  NotPassed: "NotPassed",
}
export const amendmentStatusCode = {
  Pending: "Pending",
  Passed: "Passed",
  NotPassed: "NotPassed",
  Skipped: "Skipped",
}
export const itemStatusCode = {
  Inactive: "Inactive",
  Active: "Active",
  Deleted: "Deleted",
  Suspended: "Suspended",
  Archived: "Archived",
}
export const voteStatusCode = {
  VoteFor: 0,
  VoteAgainst: 1,
  VoteAbstain: 2,
}
