import React from "react"

const { overwrite } = require("country-list")

const CountryOverwrite = () => {
  overwrite([
    {
      code: "TW",
      name: "Taiwan",
    },
  ])

  overwrite([
    {
      code: "BO",
      name: "Plurinational State of Bolivia",
    },
  ])

  overwrite([
    {
      code: "BQ",
      name: "Sint Eustatius and Saba Bonaire",
    },
  ])

  overwrite([
    {
      code: "CD",
      name: "Democratic Republic of the Congo",
    },
  ])

  overwrite([
    {
      code: "IR",
      name: "Islamic Republic of Iran",
    },
  ])

  overwrite([
    {
      code: "KP",
      name: "Democratic People's Republic of Korea",
    },
  ])

  overwrite([
    {
      code: "MD",
      name: "Republic of Moldova",
    },
  ])

  overwrite([
    {
      code: "KR",
      name: "Republic of Korea",
    },
  ])

  overwrite([
    {
      code: "TZ",
      name: "United Republic of Tanzania",
    },
  ])

  overwrite([
    {
      code: "CN",
      name: "People's Republic of China",
    },
  ])

  return <></>
}

export default CountryOverwrite
