import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { MUTATION_LOGIN } from "../../graphql/mutations"
import { tokenField } from "../../constant"

import "swiper/swiper-bundle.css"
import AnimatedButton from "../common/Buttons/animatedButton"

const Mixed = ({ tailwind, index }) => {
  const history = useHistory()
  const [pageIndex, setPageIndex] = useState(0)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [login] = useMutation(MUTATION_LOGIN)

  const handleLoginRequest = (discardButtonEffect) => {
    if (!email || !password) {
      alert("Please fill in all required fields")
      discardButtonEffect()
      return
    }

    login({
      variables: {
        email,
        password,
      },
    })
      .then((data) => {
        localStorage.clear()
        localStorage.setItem(tokenField, data.data.userLogin.token)
        setEmail("")
        setPassword("")
        window.location.href = "/"
      })
      .catch((res) => {
        if (res.graphQLErrors) {
          res.graphQLErrors.map((error) => alert("Error", error.message))
          discardButtonEffect()
          return
        }
        alert("Error", "System is currently unavailable.")
        discardButtonEffect()
      })
  }

  useEffect(() => {
    const pageSwiper = document.querySelector(".swiper-container").swiper
    history.listen((location) => {
      const currentIndex = location.pathname.indexOf("email") > -1 ? 0 : 1
      if (pageSwiper.realIndex !== currentIndex) {
        setPageIndex(currentIndex)
        pageSwiper.slideTo(currentIndex)
      }
    })
  })

  const handlePageChnange = (realindex) => {
    setPageIndex(realindex)
    if (realindex === 0) history.push("/login/email")
    else history.push("/login/code")
  }

  return (
    <div className={`${tailwind}  h-screen bg-white`}>
      <div className="z-50 pagination h-center">
        <span
          className={`swiper-pagination-bullet ${
            pageIndex === 0 ? "active email" : "code"
          } `}
        />
        <span
          className={` ml-1 swiper-pagination-bullet ${
            pageIndex === 1 ? "active code" : "email"
          } `}
        />
      </div>
      <Swiper
        onSlideChange={(a) => handlePageChnange(a.realIndex)}
        noSwipingClass="noSwiping"
        effect="fade"
        initialSlide={index}
      >
        <SwiperSlide>
          <div className="flex flex-col justify-center w-screen h-screen bg-blue-primary">
            <div className="flex-col justify-center m-auto mt-20 text-center">
              <h1 className="text-4xl font-bold text-white ">Email</h1>
              <p className="text-sm text-white ">for chairs and directors.</p>
              <input
                type="email"
                className="block px-4 py-3 mt-12 text-xs bg-white rounded-full noSwiping focus:outline-none w-70 placeholder-gray-dark"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="block px-4 py-3 mt-3 text-xs bg-white rounded-full noSwiping focus:outline-none w-70 placeholder-gray-dark"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <AnimatedButton
                activeState="true"
                tailwind="noSwiping border-none w-1/2 h-10 text-blue-primary text-xs bg-white mt-6 rounded-full"
                text="Login"
                onClick={handleLoginRequest}
              />
            </div>
            <div className="flex justify-center w-full mb-6">
              <div className="text-center ">
                <a href="#1" className="block text-xs text-white ">
                  Sign Up Here!
                </a>
                <a href="#2" className="block text-xs text-white mt-0-1">
                  Click me if you forgot your password?
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="flex flex-col justify-center w-screen h-screen bg-white">
            <div className="flex-col justify-center m-auto mt-20 text-center">
              <h1 className="text-4xl font-bold text-blue-primary">Code</h1>
              <p className="text-sm  text-blue-primary">
                for chairs and directors.
              </p>
              <input
                type="text"
                className="block px-4 py-3 mt-12 text-xs rounded-full noSwiping focus:outline-none w-70 bg-gray-light placeholder-gray-dark "
                placeholder="Enter Code"
              />
              <AnimatedButton
                activeState="true"
                tailwind="noSwiping border-none w-1/2 h-10 text-xs text-white  bg-blue-primary mt-6 rounded-full "
                text="Login"
              />
            </div>
            <div className="flex justify-center w-full mb-6">
              <div className="text-center ">
                <a href="#1" className="block text-xs  text-blue-primary">
                  Sign Up Here!
                </a>
                <a href="#2" className="block text-xs text-blue-primary mt-0-1">
                  Click me if you forgot your code?
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

Mixed.propTypes = {
  tailwind: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default Mixed
