import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

function Modal({ isShown, onSubmit, children, hideSubmitButton, close }) {
  const { t } = useTranslation()

  return isShown ? (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault()

                onSubmit()
              }}
            >
              <div className="px-8 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                {children}
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                {!hideSubmitButton ? (
                  <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                      type="submit"
                      className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green sm:text-sm sm:leading-5"
                    >
                      {t("dashboard.modal.submit")}
                    </button>
                  </span>
                ) : (
                  <></>
                )}

                <span className="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    onClick={() => close()}
                  >
                    {t("dashboard.modal.close")}
                  </button>
                </span>
              </div>
            </form>
          </>
        </div>
      </div>
    </div>
  ) : null
}

Modal.defaultProps = {
  hideSubmitButton: false,
  onSubmit: () => {},
}

Modal.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
  hideSubmitButton: PropTypes.bool,
  close: PropTypes.func.isRequired,
}

export default Modal
