/* eslint-disable no-underscore-dangle */
import { useQuery } from "@apollo/client"
import { QUERY_FETCH_ROLES } from "../../graphql/queries"
import { roleCode, itemStatusCode, type } from "../../constant"

const useDirectors = (USID) => {
  const { data, loading, refetch, onCompleted } = useQuery(QUERY_FETCH_ROLES, {
    variables: {
      USID,
    },
    fetchPolicy: "network-only",
    skip: !USID,
  })

  const directors =
    data &&
    data.user &&
    data.user.roles.filter(
      (role) =>
        role.role === roleCode.Director &&
        role.status === itemStatusCode.Active &&
        role.roleTarget.__typename === type.Conference
    )

  return { directors, loading, refetch, onCompleted }
}

export default useDirectors
