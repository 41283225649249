import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import EditIcon from "../Icons/Edit"
import ConferenceContext from "../../contexts/ConferenceContex"

function ConferenceOption({
  conference,
  isClose,
  onClick,
  iconColor,
  hoverIconColor,
}) {
  const history = useHistory()

  const { conferenceContext, setConferenceContext } = useContext(
    ConferenceContext
  )

  return (
    <div className="flex items-center justify-between mb-4 text-xs font-bold text-gray-500">
      <div
        className="inline-block hover:text-white"
        key={conference._id}
        onClick={() => {
          setConferenceContext(conference)
          history.push(`/conference/${conference._id}`)
        }}
      >
        {conference.name}
      </div>
      {isClose ? (
        <></>
      ) : (
        <div className="inline-block ml-4" onClick={onClick}>
          <EditIcon color={iconColor} hoverColor={hoverIconColor} />
        </div>
      )}
    </div>
  )
}

export default ConferenceOption
