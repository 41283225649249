/* eslint-disable no-underscore-dangle */
import React, { useState, useRef } from "react"
import ReactCountryFlag from "react-country-flag"
import { Transition } from "@tailwindui/react"
import { useMutation } from "@apollo/client"
import PropTypes from "prop-types"
import Modal from "./index"
import CountryOverwrite from "../../Miscellaneous/CountryOverwrite"
import { roleCode } from "../../../constant"
import { MUTATION_REGENERATE_LOGINCODE } from "../../../graphql/mutations"

const { getData, getName } = require("country-list")

function countryOptionGenerator(country) {
  return (
    <option key={country.code} value={country.code}>
      {country.name}
    </option>
  )
}

function EditRoleModal(props) {
  const allCountryList = getData()
  const data = props.data || {}
  const [delegate, setDelegate] = useState({})
  const [admin, setAdmin] = useState({})
  const [chair, setChair] = useState({})
  const [resetLoginCode] = useMutation(MUTATION_REGENERATE_LOGINCODE)

  const loginCodeRef = useRef(null)

  const close = () => {
    setDelegate({})
    setChair({})
    setAdmin({})
    props.close()
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(loginCodeRef.current.value)
      .then(() => {
        // Success!
      })
      .catch(() => {
        alert("Oops. Something happened. Please copy the code manually")
      })
  }

  const regenerateLoginCode = () => {
    resetLoginCode({
      variables: {
        RID: data && data._id,
      },
    }).then((e) => {
      setDelegate({
        ...delegate,
        detail: {
          ...delegate.detail,
          loginCode: e.data.resetLoginCode.role.detail.loginCode,
        },
      })
    })
  }

  return (
    <Modal
      close={close}
      isShown={props.isShown}
      onSubmit={(e) => {
        const returnData = {
          targetID: data._id,
          role: data.role,
          delegate,
        }

        props.onSubmit(returnData).then(() => {
          setDelegate({})
          props.close()
        })
      }}
    >
      <Transition show={data.role === roleCode.Delegate}>
        <div className="flex flex-wrap mb-2 -mx-3">
          <CountryOverwrite />
          <div className="w-full">
            <div className="text-center">
              <div className="text-xl font-bold">
                {delegate.delegation ||
                  (data.detail &&
                    data.detail.representative &&
                    data.detail.representative.displayName) ||
                  (data.detail &&
                    data.detail.representative &&
                    data.detail.representative.name)}
              </div>
              <ReactCountryFlag
                countryCode={
                  delegate.code ||
                  (data.detail &&
                    data.detail.representative &&
                    data.detail.representative.code
                    ? data.detail.representative.code
                    : "US")
                }
                svg
                style={{
                  width: "8em",
                  height: "8em",
                }}
              />
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="grid-rop"
              >
                Login Code
              </label>
              <button
                type="button"
                className="px-4 py-2 m-2 font-semibold bg-transparent border border-blue-500 rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
                onClick={copyToClipboard}
              >
                Copy:{" "}
                {(delegate && delegate.detail && delegate.detail.loginCode) ||
                  (data && data.detail && data.detail.loginCode)}
              </button>
              <button
                type="button"
                className="px-4 py-2 m-2 font-semibold bg-transparent border border-red-500 rounded hover:bg-red-500 hover:text-white hover:border-transparent"
                onClick={regenerateLoginCode}
              >
                Regenerate Login Code
              </button>
              <textarea
                readOnly
                className="hidden"
                value={delegate && delegate.detail && delegate.detail.loginCode}
                ref={loginCodeRef}
              />

              {/* <div className="flex flex-row my-2">
                <label
                  className="block py-3 mx-4 mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-delegation"
                >
                  Delegation
                </label>
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-delegation"
                  value={
                    delegate.code ||
                    (delegate && delegate.detail && delegate.detail.loginCode)
                  }
                  onChange={(e) =>
                    setDelegate({
                      ...delegate,
                      code: e.target.value,
                      delegation: getName(e.target.value),
                    })
                  }
                >
                  <option key={0} value="">
                    -
                  </option>
                  {allCountryList.map(countryOptionGenerator)}
                </select>
              </div>
              <div className="flex flex-row my-2">
                <label
                  className="block py-3 pr-8 mx-4 text-xs font-bold tracking-wide text-gray-700"
                  htmlFor="grid-delegation"
                >
                  Display Name
                </label>
                <input
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  onChange={(e) =>
                    setDelegate({
                      ...delegate,
                      displayName: e.target.value,
                    })
                  }
                />
              </div> */}

              <div className="flex flex-row my-2 text-center px-1">
                <code>
                  {data && data.detail && data.detail.representative && JSON.stringify(data.detail.representative)}
                </code>
              </div>

              {/* Custom Delegation */}
              {/* <div className="flex flex-row">
                                <label className="block py-3 mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-delegation">
                                    Custom Delegation
                                </label>
                                <input className="block w-full px-2 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" id="grid-display-delegation" 
                                type="text" placeholder="WHO" onChange={(e) => setDelegate({...delegate, customDelegation: e.target.value})}/>
                            </div>

                            <div className="flex flex-row">
                                <label className="block py-3 mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="grid-delegation">
                                Flag Icon URL
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500" id="grid-display-delegation" 
                                type="text" placeholder="https://flag-icon.png" onChange={(e) => setDelegate({...delegate, customFlagSrc: e.target.value})}/>
                                <div className="px-3 py-4 mb-3">
                                    <img width="40" height="40" src={delegate.customFlagSrc ? delegate.customFlagSrc : "https://munative-asia-01.s3-ap-southeast-1.amazonaws.com/MunativeLogo.png"}></img>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </Transition>
    </Modal>
  )
}

EditRoleModal.defaultProps = {
  isShown: false,
}

EditRoleModal.propTypes = {
  close: PropTypes.func.isRequired,
  isShown: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

export default EditRoleModal
