import React from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import PublicRoute from "./components/Routers/PublicRoute"
import PrivateRoute from "./components/Routers/PrivateRoute"
import "./styles/app.css"

import Dashboard from "./components/Dashboard"
import Login from "./components/Login/index"

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute path="/login" component={Login} />

          <PrivateRoute
            path={[
              "/",
              "/conference/:confId/:committeeId",
              "/conference/:confId",
              "/conference",
            ]}
            component={Dashboard}
          />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
