/* eslint-disable camelcase */
import React, { useContext } from "react"
import RoleSwitch from "../OrganizationItems/RoleSwitch"
import LogoutOption from "../../common/Options/LogoutOption"
import OrganizationName from "../OrganizationItems/OrganizationName"
import OrganizationItems from "../OrganizationItems"
import CollapseContext from "../../contexts/CollapseContext"

function CollapsibleMenu() {
  const { conferenceMenuCloseContext } = useContext(CollapseContext)
  return (
    <div
      className={`hidden md:bg-gray-800 md:${
        conferenceMenuCloseContext ? "hidden" : "col-span-2"
      } md:flex md:flex-col md:justify-between md:p-3`}
    >
      <div className="mt-3">
        <OrganizationName />
        <RoleSwitch />
        <OrganizationItems />
      </div>
      <LogoutOption isClose={conferenceMenuCloseContext} />
    </div>
  )
}

export default CollapsibleMenu
