import React, { useState, useMemo, useCallback } from "react"
import { Redirect } from "react-router-dom"
import jwt_decode from "jwt-decode"
import FixedMenu from "./FixedMenu/index"
import CollapsibleMenu from "./CollapsibleMenu"
import useDirector from "../hooks/useDirectors"
import { tokenField } from "../../constant"
import OrganizationContext from "../contexts/OrganizationContext"
import ConferenceContex from "../contexts/ConferenceContex"
import CommitteeContext from "../contexts/CommitteeContext"
import CollapseContext from "../contexts/CollapseContext"

function Dashboard() {
  const user = useMemo(
    () =>
      localStorage.getItem(tokenField)
        ? jwt_decode(localStorage.getItem(tokenField))
        : null,
    []
  )

  const [conferenceMenuCloseContext, setConferenceMenuCloseContext] = useState(
    false
  )
  const [organizationContext, setOrganizationContext] = useState({})
  const [conferenceContext, setConferenceContext] = useState({})
  const [committeeContext, setCommitteeContext] = useState({})

  const { directors, loading } = useDirector((user && user._id) || "")
  const isDirector = directors && directors.length !== 0

  return (
    <div className="grid h-screen grid-cols-12 gap-0">
      {!loading ? isDirector ? <></> : <Redirect to="/login/email" /> : <></>}
      <CollapseContext.Provider
        value={{ conferenceMenuCloseContext, setConferenceMenuCloseContext }}
      >
        <OrganizationContext.Provider
          value={{ organizationContext, setOrganizationContext }}
        >
          <ConferenceContex.Provider
            value={{ conferenceContext, setConferenceContext }}
          >
            <CommitteeContext.Provider
              value={{ committeeContext, setCommitteeContext }}
            >
              <CollapsibleMenu
                conferenceMenuClose={conferenceMenuCloseContext}
              />
              <FixedMenu />
            </CommitteeContext.Provider>
          </ConferenceContex.Provider>
        </OrganizationContext.Provider>
      </CollapseContext.Provider>
    </div>
  )
}

export default Dashboard
