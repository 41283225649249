import React from "react"
import { gql, useMutation, useQuery } from '@apollo/client'
import { committeeType } from "../../prop-types"

const FETCH_COMMITTEE_OPTIONS = gql`
query fetchCommitteeOptions($CMID: ID!){
  committee(CMID: $CMID) {
    _id
    options
  }
}
`

const MUTATE_UPDATE_CM_OPTIONS = gql`
mutation updateCommitteeOptions($CMID: ID!, $cmOption: JSON!){
  updateCommitteeOptions(input: {
    CMID: $CMID
    options: $cmOption
  }) {
    committee{
      _id
    }
  }
}
`

function CommitteeDetail({ committee }) {

  const { data, refetch } = useQuery(FETCH_COMMITTEE_OPTIONS, {
    variables: {
      CMID: committee && committee._id
    },
    skip: !(committee && committee._id)
  });

  const cmOption = (data && data.committee && data.committee.options) || [];
  const forceNoteReview = !!(cmOption && cmOption.forceNoteReview);

  const [updateCmOptions, { loading: updateCMOptionLoading }] = useMutation(MUTATE_UPDATE_CM_OPTIONS)

  return (
    <div>
      <h1 className="text-xl text-blue-500 text-bold">Committee Detail</h1>
      <h3 className="text-xs font-light">{(committee && committee.name) || "N/A"}</h3>
      <h3 className="text-xs font-light">Force Note Review: {forceNoteReview ? <span className="text-green-600">Yes</span> : <span className="text-red-600">No</span>}</h3>
      {committee && committee._id ?
        <button
          disabled={updateCMOptionLoading}
          className={`px-4 py-2 text-sm font-bold text-white rounded bg-${forceNoteReview ? "red" : "green"}-500 hover:bg-${forceNoteReview ? "red" : "green"}-700`}
          onClick={() => {
            updateCmOptions({
              variables: {
                CMID: committee._id,
                cmOption: {
                  forceNoteReview: !forceNoteReview
                }
              }
            }).then(() => {
              refetch();
            });
          }}
        >{forceNoteReview ? "Disable" : "Enable"} note review</button>
        : null}
    </div>
  )
}

CommitteeDetail.propTypes = {
  committee: committeeType
}

export default CommitteeDetail
