import React from "react"
import { useTranslation } from "react-i18next"

function LogoutOption(props) {
  const { t } = useTranslation()
  const logout = () => {
    localStorage.clear()
    window.location.href = "/login/email"
  }

  return (
    <div
      className="py-2 text-xs font-bold text-gray-500 hover:text-white"
      onClick={() => logout()}
    >
      {t("dashboard.system.logout")}
    </div>
  )
}

export default LogoutOption
