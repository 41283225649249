import { useQuery } from "@apollo/client"
import { QUERY_FETCH_CONFERENCE } from "../../graphql/queries"

const useConference = (CFID) => {
  const { data, loading, refetch } = useQuery(QUERY_FETCH_CONFERENCE, {
    variables: {
      CFID,
    },
    fetchPolicy: "network-only",
    skip: !CFID,
  })

  const conference = (data && data.conference) || {}
  const committees =
    (data && data.conference && data.conference.committees) || []

  return { conference, committees, loading, refetch }
}

export default useConference
