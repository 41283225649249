import { useQuery } from "@apollo/client"
import { QUERY_FETCH_COMMITTEES } from "../../graphql/queries"

const useCommittees = (CFID) => {
  const { data, loading, refetch } = useQuery(QUERY_FETCH_COMMITTEES, {
    variables: {
      CFID,
    },
    fetchPolicy: "network-only",
    skip: !CFID,
  })

  const committees =
    (data && data.conference && data.conference.committees) || []

  return { committees, loading, refetch }
}

export default useCommittees
