/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext } from "react"
import { useMutation } from "@apollo/client"
import AddOption from "../../common/Options/AddOption"

import {
  MUTATION_ADD_CONFERENCE,
  MUTATION_EDIT_CONFERENCE,
} from "../../../graphql/mutations"
import ConferenceOption from "../../common/Options/ConferenceOption"
import AddConferenceModal from "../../common/Modals/AddConferenceModal"
import EditConferenceModal from "../../common/Modals/EditConferenceModal"

import OrganizationContext from "../../contexts/OrganizationContext"

import useConferences from "../../hooks/useConferences"

function OrganizationItems() {
  const { organizationContext } = useContext(OrganizationContext)

  const { conferences, refetch } = useConferences(
    (organizationContext && organizationContext._id) || ""
  )

  const [openedModal, setOpenedModal] = useState({
    editIsShown: false,
    addIsShown: false,
    targetID: "",
    onSubmitFunc: () => {},
  })

  const closeModal = () => {
    setOpenedModal({
      editIsShown: false,
      addIsShown: false,
      targetID: "",
      onSubmitFunc: () => {},
    })
  }

  const [editConference] = useMutation(MUTATION_EDIT_CONFERENCE, {
    onCompleted: () => {
      refetch()
    },
  })
  const [addConference] = useMutation(MUTATION_ADD_CONFERENCE, {
    onCompleted: () => {
      refetch()
    },
  })

  const onAddConferenceSubmit = (data) => {
    return addConference({
      variables: {
        ORGID: (organizationContext && organizationContext._id) || "",
        name: data.name,
        status: data.status,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime).toISOString(),
        logoSrc: data.logoSrc,
      },
    })
  }

  const onEditConferenceSubmit = (data) => {
    return editConference({
      variables: {
        CFID: data.CFID,
        name: data.name,
        status: data.status,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime).toISOString(),
        logoSrc: data.logoSrc,
      },
    })
  }

  return (
    <div>
      {conferences.map((conference) => (
        <div key={conference._id}>
          <ConferenceOption
            conference={conference}
            iconColor="text-gray-500"
            hoverIconColor="text-white"
            onClick={() => {
              setOpenedModal({
                editIsShown: true,
                onSubmitFunc: onEditConferenceSubmit,
                targetID: conference._id,
              })
            }}
          />
        </div>
      ))}
      <AddOption
        type="Conference"
        iconColor="text-gray-500"
        hoverIconColor="text-white"
        onClick={() => {
          setOpenedModal({
            addIsShown: true,
            onSubmitFunc: onAddConferenceSubmit,
          })
        }}
      />
      <AddConferenceModal
        onSubmit={openedModal.onSubmitFunc}
        close={closeModal}
        isShown={openedModal.addIsShown}
      />
      <EditConferenceModal
        onSubmit={openedModal.onSubmitFunc}
        close={closeModal}
        isShown={openedModal.editIsShown}
        targetID={openedModal.targetID}
      />
    </div>
  )
}

export default OrganizationItems
