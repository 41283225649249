/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode"
import { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
  MUTATION_EDIT_ROLE,
  MUTATION_ADD_ROLE,
  MUTATION_CHAIR_INVITE,
} from "../../../../graphql/mutations"
import { roleCode, roleField } from "../../../../constant"
import useCommitteeRoles from "../../../hooks/useCommitteeRoles"
import { QUERY_FETCH_ROLES } from "../../../../graphql/queries"

export function useCommitteeItems(committeeId) {
  const me = localStorage.getItem(roleField)
    ? jwt_decode(localStorage.getItem(roleField))
    : ""

  //= ===============================================================================
  // Hooks
  //= ===============================================================================

  /* Custom Hooks Start */

  const { loading, chairs, admins, delegates, refetch } = useCommitteeRoles(
    committeeId
  )

  /* Custom Hooks End */

  /* useState Hooks Start */

  const [openedModal, setOpenedModal] = useState({
    editIsShown: false,
    addIsShown: false,
    viewIsShown: false,
    targetID: committeeId,
    data: {},
    onSubmitFunc: () => { },
  })

  /* useState Hooks End */

  /* useMutation Hooks Start */

  const [editRole] = useMutation(MUTATION_EDIT_ROLE, {
    onCompleted: () => {
      refetch()
    },
  })

  const [addRole] = useMutation(MUTATION_ADD_ROLE, {
    onCompleted: () => {
      refetch()
    },
  })

  const [chairInvite] = useMutation(MUTATION_CHAIR_INVITE, {
    onCompleted: () => {
      refetch();
    }
  })

  /* useMutation Hooks End */

  /* useQuery Hooks Start */

  const { data: rolesData, loading: rolesLoading } = useQuery(
    QUERY_FETCH_ROLES,
    {
      variables: {
        USID: me._id,
      },
      fetchPolicy: "network-only",
      skip: !me,
    }
  )

  /* useQuery Hooks End */

  //= ===============================================================================
  // Functions
  //= ===============================================================================

  /* Custom Functions Start */

  const closeModal = () => {
    setOpenedModal({
      editIsShown: false,
      addIsShown: false,
      onSubmitFunc: () => { },
    })
  }

  const onEditRoleSubmit = (data) => {
    return new Promise((resolve) => {
      // Edit delegate
      if (data.role === roleCode.Delegate) {
        editRole({
          variables: {
            RID: data.targetID,
            detail: {
              representative: {
                code: data.delegate.code,
                name: data.delegate.delegation,
                displayName: data.delegate.displayName,
              },
            },
          },
        })
      }
      resolve(data)
    })
  }

  const onAddRoleSubmit = (data) => {
    return new Promise((resolve, reject) => {
      // Add Delegates By List
      if (data.role === roleCode.Delegate && data.type === "list") {
        for (let i = 0; i < data.delegates.code.length; i += 1) {
          addRole({
            variables: {
              roleTarget: committeeId,
              roleTargetType: "Committee",
              role: roleCode.Delegate,
              detail: {
                representative: {
                  code: data.delegates.code[i],
                  name: data.delegates.displayName[i],
                },
              },
            },
          })
        }
      }

      // Add Delegates By Individual
      if (data.role === roleCode.Delegate && data.type === "individual") {
        addRole({
          variables: {
            roleTarget: committeeId,
            roleTargetType: "Committee",
            role: roleCode.Delegate,
            detail: {
              representative: {
                code: !data.isCustom ? data.delegate.code : "",
                name: data.delegate.customDelegation
                  ? data.delegate.customDelegation
                  : data.delegate.delegation,
              },
            },
          },
        })
      }

      // Add Admin
      if (data.role === roleCode.Admin) {
        addRole({
          variables: {
            roleTarget: committeeId,
            roleTargetType: "Committee",
            role: roleCode.Admin,
            detail: {
              name: `Admin ${data.admin.name}`,
            },
          },
        })
      }

      // Add Chair
      if (data.role === roleCode.Chair) {
        chairInvite({
          variables: {
            CMID: committeeId,
            email: data.chair.email,
            firstName: data.chair.firstName,
            lastName: data.chair.lastName
          }
        }).then(() => {
          resolve(data)
        }).catch((err) => {
          reject(err)
        })
      } else {
        // temporary work around
        resolve(data)
      }
    })
  }

  /* Custom Functions End */

  return {
    onEditRoleSubmit,
    onAddRoleSubmit,
    loading,
    chairs,
    admins,
    delegates,
    openedModal,
    setOpenedModal,
    closeModal,
    rolesData,
    rolesLoading,
  }
}
