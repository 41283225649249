import React from "react"
import { useTranslation } from "react-i18next"
import { committeeType } from "../../prop-types"

function CommitteeStatus({ committee }) {
  const { t } = useTranslation()

  const renderSwitch = (input) => {
    switch (input) {
      case "Active":
        return t("dashboard.system.active")
      case "Inactive":
        return t("dashboard.system.inactive")
      default:
        return ""
    }
  }

  return (
    <h3 className="text-xs font-light text-black">
      {(committee && renderSwitch(committee.status)) || "N/A"}
    </h3>
  )
}

CommitteeStatus.defaultProps = {
  committee: {},
}

CommitteeStatus.propTypes = {
  committee: committeeType,
}

export default CommitteeStatus
