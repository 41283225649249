import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useMutation } from "@apollo/client"
import AnimatedButton from "../common/Buttons/animatedButton"
import { MUTATION_LOGIN } from "../../graphql/mutations"
import { tokenField } from "../../constant"

const Email = ({ tailwind }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [login] = useMutation(MUTATION_LOGIN, {
    context: {
      noAuthHeader: true,
    }
  })

  const handleLoginRequest = (discardButtonEffect) => {
    if (!email || !password) {
      alert("Please fill in all required fields")
      discardButtonEffect()
      return
    }

    login({
      variables: {
        email,
        password,
      }
    })
      .then((data) => {
        localStorage.clear()
        localStorage.setItem(tokenField, data.data.userLogin.token)
        setEmail("")
        setPassword("")
        window.location.href = "/"
      })
      .catch((res) => {
        if (res.graphQLErrors) {
          res.graphQLErrors.map((error) => alert(`Error: ${error.message}`))
          discardButtonEffect()
          return
        }
        alert("Error", "System is currently unavailable.")
        discardButtonEffect()
      })
  }

  return (
    <div className={`${tailwind} flex h-screen bg-blue-primary`}>
      <div className="flex flex-col justify-center w-full md:flex">
        <div className="flex-col justify-center m-auto text-center ">
          <h1 className="text-5xl font-bold text-white">Welcome Back!</h1>
          <p className="text-base text-white ">
            Are you ready to add another conference to your <br />
            everlasting MUN journey?
          </p>
          <p className="text-sm text-white mt-36">
            Have a login code instead? Try this one!
          </p>
          <Link to="/login/code">
            <AnimatedButton
              tailwind="w-44 text-sm h-10 text-white border-gray-100 mt-3 rounded-xlg"
              text="Login with Code"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col justify-center w-full md:bg-white">
        <div className="flex-col justify-center m-auto text-center">
          <h1 className="text-5xl font-bold text-white md:text-blue-primary">
            Email
          </h1>
          <p className="text-base text-white md:text-blue-primary">
            for chairs and directors.
          </p>
          <form>
            <input
              type="email"
              className="block px-4 py-3 mt-12 text-sm bg-white rounded-full focus:outline-none w-70 md:bg-gray-light placeholder-gray-dark md:rounded-xlg"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              className="block px-4 py-3 mt-3 text-sm bg-white rounded-full focus:outline-none w-70 md:bg-gray-light placeholder-gray-dark md:rounded-xlg"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <AnimatedButton
              onClick={handleLoginRequest}
              activeState="true"
              tailwind="border-none text-sm w-1/2 h-10 text-blue-primary md:text-white bg-white md:bg-blue-primary mt-6 rounded-full md:rounded-xlg"
              text="Login"
            />
          </form>
        </div>
        <div className="flex justify-center w-full mb-6">
          <div className="text-center ">
            <a
              href="#1"
              className="block text-sm text-white md:text-blue-primary"
            >
              Sign Up Here!
            </a>
            <a
              href="#2"
              className="block text-sm text-white md:text-blue-primary mt-0-1"
            >
              Click me if you forgot your password?
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Email.propTypes = {
  tailwind: PropTypes.string.isRequired,
}

export default Email
