import React from "react"
import PropTypes from "prop-types"
import Basic from "./Basic"

export const textFieldvariants = {
  Basic: "basic",
}

/**
 * Render TextField based on the variant
 * @param {handler} onChange - The handler for value changing of textfield.
 * @param {string} placeholder - The string for placeholding.
 * @param {string} value - Value of the rendered textfield.
 * @param {string} variant - [basic, colored]
 */

export function TextField({ onChange, placeholder, value, variant }) {
  switch (variant) {
    case textFieldvariants.Basic:
      return (
        <Basic
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          variant={variant}
        />
      )
    default:
      return (
        <Basic
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          variant={variant}
        />
      )
  }
}

TextField.defaultProps = {
  placeholder: "",
}

TextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
}
