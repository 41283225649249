import React from "react"
import { useTranslation } from "react-i18next"
import { conferenceType } from "../../prop-types"

function ConferenceStatus({ conference }) {
  const { t } = useTranslation()

  const renderSwitch = (input) => {
    switch (input) {
      case "Active":
        return t("dashboard.system.active")
      case "Inactive":
        return t("dashboard.system.inactive")
      default:
        return ""
    }
  }

  return (
    <h3 className="h-8 text-xs font-light text-white">
      {renderSwitch(conference.status)}
    </h3>
  )
}

ConferenceStatus.propTypes = {
  conference: conferenceType.isRequired,
}

export default ConferenceStatus
