import gql from "graphql-tag"
import {
  FRAGMENT_ROLES_BASIC_INFO,
  FRAGMENT_ROLE_BASIC_INFO,
  FRAGMENT_OWNER_BASIC_INFO,
  FRAGMENT_COMMITTEE_BASIC_INFO,
  FRAGMENT_ROLLCALLS_INFO,
  FRAGMENT_CONFERENCE_BASIC_INFO,
  FRAGMENT_CONFERENCE_TIMESTAMP_INFO,
  FRAGMENT_ORGANIZATION_BASIC_INFO,
  FRAGMENT_ANNOUNCEMENT_INFO,
  FRAGMENT_ROLES_TARGET_INFO,
} from "./fragments"

export const QUERY_FETCH_ROLE = gql`
  query fetchRoleQuery($RID: ID!) {
    role(RID: $RID) {
      _id
      ...RoleBasicInfo
      roleTarget {
        ... on Committee {
          _id
          name
        }
        ... on Conference {
          _id
          name
        }
        ... on Organization {
          _id
          name
        }
      }
      ...OwnerBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
  ${FRAGMENT_OWNER_BASIC_INFO}
`

export const QUERY_FETCH_ROLES = gql`
  query fetchRolesQuery($USID: ID!) {
    user(USID: $USID) {
      _id
      ...RolesBasicInfo
      ...RolesTargetInfo
    }
  }
  ${FRAGMENT_ROLES_BASIC_INFO}
  ${FRAGMENT_ROLES_TARGET_INFO}
`

export const QUERY_FETCH_CONFERENCE = gql`
  query fetchConferenceQuery($CFID: ID!) {
    conference(CFID: $CFID) {
      _id
      ...ConferenceBasicInfo
      ...ConferenceTimeStampInfo
      organization {
        _id
      }
      committees {
        _id
        ...CommitteeBasicInfo
      }
    }
  }
  ${FRAGMENT_CONFERENCE_BASIC_INFO}
  ${FRAGMENT_CONFERENCE_TIMESTAMP_INFO}
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
`

export const QUERY_FETCH_CONFERENCES = gql`
  query fetchConferencesQuery($ORGID: ID!) {
    organization(ORGID: $ORGID) {
      _id
      conferences {
        _id
        ...ConferenceBasicInfo
        ...ConferenceTimeStampInfo
      }
    }
  }
  ${FRAGMENT_CONFERENCE_BASIC_INFO}
  ${FRAGMENT_CONFERENCE_TIMESTAMP_INFO}
`

export const QUERY_FETCH_COMMITTEE = gql`
  query fetchCommitteeQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      ...CommitteeBasicInfo
    }
  }
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEES = gql`
  query fetchCommitteesQuery($CFID: ID!) {
    conference(CFID: $CFID) {
      _id
      committees {
        _id
        ...CommitteeBasicInfo
      }
    }
  }
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEE_ROLLCALLS = gql`
  query fetchCommitteeRollCallsQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      rollCalls {
        _id
        ...RollCallsInfo
      }
    }
  }
  ${FRAGMENT_ROLLCALLS_INFO}
`

export const QUERY_FETCH_COMMITTEE_ROLES = gql`
  query fetchCommitteeRolesQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      roles {
        _id
        ...RoleBasicInfo
      }
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const QUERY_FETCH_CONFERENCE_ROLES = gql`
  query fetchConferenceRolesQuery($CFID: ID!) {
    conference(CFID: $CFID) {
      _id
      committees {
        _id
        ...CommitteeBasicInfo
        roles {
          _id
          ...RoleBasicInfo
        }
      }
    }
  }
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const QUERY_FETCH_ORGANIZATION = gql`
  query fetchOrganizationQuery($ORGID: ID!) {
    organization(ORGID: $ORGID) {
      _id
      ...OrganizationBasicInfo
    }
  }
  ${FRAGMENT_ORGANIZATION_BASIC_INFO}
`

export const QUERY_FETCH_ORGANIZATIONS = gql`
  query fetchOrganizationsQuery {
    organizations {
      _id
      ...OrganizationBasicInfo
    }
  }
  ${FRAGMENT_ORGANIZATION_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEE_ANNOUNCEMENTS = gql`
  query fetchCommitteeAnnouncementsQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      announcements {
        _id
        ...AnnouncementInfo
      }
    }
  }
  ${FRAGMENT_ANNOUNCEMENT_INFO}
`
