/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from "react"
import { Transition } from "@tailwindui/react"

import PropTypes from "prop-types"
import AddOption from "../../common/Options/AddOption"
import EditRoleModal from "../../common/Modals/EditRoleModal"
import AddRoleModal from "../../common/Modals/AddRoleModal"
import ViewLoginCodeModal from "../../common/Modals/ViewLoginCodeModal"

import { useCommitteeItems, useForm } from "./hooks"
import RoleDivider from "../../common/Options/RoleDivider"
import RoleOption from "../../common/Options/RoleOption"
import { TextField, textFieldvariants } from "../../common/TextFields"
import CommitteeContext from "../../contexts/CommitteeContext"

export default function CommitteeItems() {
  const { committeeContext } = useContext(CommitteeContext)

  const {
    onEditRoleSubmit,
    onAddRoleSubmit,
    loading,
    chairs,
    admins,
    delegates,
    openedModal,
    setOpenedModal,
    closeModal,
  } = useCommitteeItems(committeeContext._id)

  const { search, setSearch } = useForm()

  return (
    <div>
      <Transition show={!!committeeContext._id}>
        <AddOption
          type="Role"
          iconColor="text-black"
          hoverIconColor="text-blue-600"
          onClick={() => {
            setOpenedModal({
              addIsShown: true,
              onSubmitFunc: onAddRoleSubmit,
            })
          }}
        />
      </Transition>
      <div className="overflow-auto h-128">
        <RoleDivider
          type="Chairs"
          number={chairs.length}
          color="text-green-500"
        />
        {chairs.map((v) => (
          <div
            key={v._id}
            onClick={() => {
              setOpenedModal({
                editIsShown: true,
                onSubmitFunc: onEditRoleSubmit,
                data: v,
              })
            }}
          >
            <RoleOption
              role={v}
              name={(v.detail && v.detail.name) || "Chair"}
            />
          </div>
        ))}
        <RoleDivider
          type="Admin Staffs"
          number={admins.length}
          color="text-yellow-500"
        />
        {!loading ? (
          admins
            .sort((a, b) => {
              if (a.detail === null || b.detail === null) return -1
              return a.detail.name > b.detail.name ? 1 : -1
            })
            .map((v) => (
              <div
                key={v._id}
                onClick={() => {
                  setOpenedModal({
                    editIsShown: true,
                    onSubmitFunc: onEditRoleSubmit,
                    data: v,
                  })
                }}
              >
                <RoleOption
                  role={v}
                  name={(v.detail && v.detail.name) || "Admin"}
                />
              </div>
            ))
        ) : (
          <></>
        )}
        <RoleDivider
          type="Delegates"
          number={delegates.length}
          color="text-blue-500"
        />
        <button
          type="button"
          className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          disabled={!committeeContext._id}
          onClick={() => {
            setOpenedModal({
              viewIsShown: true,
              onSubmitFunc: onEditRoleSubmit,
              data: delegates,
            })
          }}
        >
          List All LoginCode
        </button>
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          value={search}
          variant={textFieldvariants.Basic}
        />
        {!loading ? (
          delegates
            .filter((country) => {
              if (country.detail.representative === undefined) return null
              return country.detail.representative.name
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(search.toLowerCase().replace(/\s/g, ""))
            })
            .sort((a, b) => {
              if (
                a.detail.representative === null ||
                b.detail.representative === null
              )
                return -1
              return a.detail.representative.name > b.detail.representative.name
                ? 1
                : -1
            })
            .map((v) => (
              <div
                key={v._id}
                onClick={() => {
                  setOpenedModal({
                    editIsShown: true,
                    onSubmitFunc: onEditRoleSubmit,
                    data: v,
                  })
                }}
              >
                <RoleOption
                  role={v}
                  name={
                    (v.detail &&
                      v.detail.representative &&
                      v.detail.representative.displayName) ||
                    (v.detail &&
                      v.detail.representative &&
                      v.detail.representative.name) ||
                    "N/A"
                  }
                />
              </div>
            ))
        ) : (
          <></>
        )}
      </div>
      <EditRoleModal
        onSubmit={openedModal.onSubmitFunc}
        close={closeModal}
        isShown={openedModal.editIsShown}
        data={openedModal.data}
        targetID={openedModal.targetID}
      />
      <AddRoleModal
        onSubmit={openedModal.onSubmitFunc}
        close={closeModal}
        isShown={openedModal.addIsShown}
      />
      <ViewLoginCodeModal
        close={closeModal}
        isShown={openedModal.viewIsShown}
        data={openedModal.data}
      />
    </div>
  )
}

CommitteeItems.defaultProps = {
  committeeId: "",
}

CommitteeItems.propTypes = {
  committeeId: PropTypes.string,
}
