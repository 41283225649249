import React, { useState } from "react"
import PropTypes from "prop-types"

const AnimatedButton = ({ tailwind, onClick, text, activeState }) => {
  const [clicked, setOnclicked] = useState(false)

  const handleClick = () => {
    setOnclicked(activeState)
    if (onClick) {
      onClick(() => setOnclicked(false))
    } else {
      console.error("This button should have an onclick function to execute.")
    }
  }

  return (
    <div
      onClick={handleClick}
      onKeyPress={null}
      role="button"
      tabIndex="0"
      className={`${tailwind} button overflow-hidden relative border-2/3 focus:outline-none cursor-pointer mx-auto font-bold`}
    >
      <div
        className={`${
          clicked ? "active" : ""
        } animation-hover w-over bg-blue-light h-over`}
      >
        <div
          className={`animation-click w-over bg-orange-primary h-over ${
            clicked ? "active" : ""
          }`}
        />
      </div>
      <font className={`w-40 b-center  ${clicked ? "active" : ""}`}>
        {text}
      </font>
    </div>
  )
}

AnimatedButton.defaultProps = {
  onClick: () => {},
  text: "",
  activeState: false,
}

AnimatedButton.propTypes = {
  tailwind: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
  activeState: PropTypes.bool,
}

export default AnimatedButton
