import { useQuery } from "@apollo/client"
import { QUERY_FETCH_CONFERENCES } from "../../graphql/queries"

const useConferences = (ORGID) => {
  const { data, loading, refetch } = useQuery(QUERY_FETCH_CONFERENCES, {
    variables: {
      ORGID,
    },
    fetchPolicy: "network-only",
    skip: !ORGID,
  })

  const conferences =
    (data && data.organization && data.organization.conferences) || []

  return { conferences, loading, refetch }
}

export default useConferences
