/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Modal from "./index"
import { itemStatusCode } from "../../../constant"
import useConference from "../../hooks/useConference"
import Spinner from "../Spinners/Spinner1"

function EditConferenceModal({ targetID, close, isShown, onSubmit }) {
  const { t } = useTranslation()

  const { conference, loading } = useConference(targetID)

  const [startDateEndDateError, setstartDateEndDateError] = useState(false)

  const [name, setName] = useState("")
  const [status, setStatus] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [logoSrc, setLogoSrc] = useState("")

  // Fetch Current Conference Data
  useEffect(() => {
    setName(conference.name)
    setStatus(conference.status)
    setStartTime(moment(conference.startTime).format("YYYY-MM-DDThh:mm"))
    setEndTime(moment(conference.endTime).format("YYYY-MM-DDThh:mm"))
    setLogoSrc(conference.logoSrc)
  }, [conference])

  return (
    <>
      <Modal
        close={close}
        isShown={isShown}
        onSubmit={() => {
          const data = {
            CFID: conference._id,
            name,
            status,
            startTime,
            endTime,
            logoSrc,
          }

          if (startTime > endTime) {
            setstartDateEndDateError(true)
            return
          }

          onSubmit(data).then(() => {
            setName("")
            setStatus("")
            setStartTime("")
            setEndTime("")
            setLogoSrc("")
            setstartDateEndDateError(false)
            close()
          })
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-conference-name"
                >
                  {t(
                    "dashboard.organizations.modal.editConference.conferenceName"
                  )}
                </label>
                <input
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-conference-name"
                  type="text"
                  placeholder="THIMUN"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="w-full px-3 md:w-1/2">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-status"
                >
                  {t(
                    "dashboard.organizations.modal.editConference.conferenceStatus"
                  )}
                </label>
                <div className="relative">
                  <select
                    className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={itemStatusCode.Active}>
                      {t("dashboard.system.active")}
                    </option>
                    <option value={itemStatusCode.Inactive}>
                      {t("dashboard.system.inactive")}
                    </option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-startTime"
                >
                  {t(
                    "dashboard.organizations.modal.addConference.conferenceStartTime"
                  )}
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    startDateEndDateError ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="grid-startTime"
                  type="datetime-local"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-endTime"
                >
                  {t(
                    "dashboard.organizations.modal.addConference.conferenceEndTime"
                  )}
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    startDateEndDateError ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="grid-endTime"
                  type="datetime-local"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
                {startDateEndDateError ? (
                  <p className="text-xs italic text-red-500">
                    {t(
                      "dashboard.organizations.modal.error.startEndTimeValidationError"
                    )}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3 ">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-icon"
                >
                  {t(
                    "dashboard.organizations.modal.addConference.conferenceLogo"
                  )}
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-icon"
                  type="text"
                  placeholder="https://munative.com/logo.ico"
                  value={logoSrc}
                  onChange={(e) => setLogoSrc(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-6 -mx-3">
              <div className="w-full px-3 ">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-icon"
                >
                  {t("dashboard.organizations.modal.addConference.logoPreview")}
                </label>
                <img alt="Not Found" width="40" height="40" src={logoSrc} />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

EditConferenceModal.defaultProps = {
  targetID: "",
}

EditConferenceModal.propTypes = {
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  targetID: PropTypes.string,
  isShown: PropTypes.bool.isRequired,
}

export default EditConferenceModal
