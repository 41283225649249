import React from "react"
import { ClipLoader } from "react-spinners"

function Spinner() {
  return (
    <div className="flex justify-center">
      <ClipLoader color="#4299e1" />
    </div>
  )
}

export default Spinner
