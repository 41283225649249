import React from "react"

function ResoCard() {
  return (
    <div className="rounded shadow-lg bg-white h-64">
      <div className="px-6 py-4">
        <h3 className="text-black font-bold text-sm">Resolution</h3>
        <h3 className="text-black text-xs">
          Strengthening UN Coordination of humanitarian
        </h3>
        <h3 className="text-black font-bold text-sm mt-2">Amendment</h3>

        <div className="flex justify-between mt-2">
          <div>
            <h3 className="font-light text-sm">Total</h3>
            <h3 className="font-bold text-xl">5</h3>
          </div>
          <div>
            <h3 className="font-light text-sm">Pass Rate</h3>
            <h3 className="font-bold text-xl">54.6%</h3>
          </div>
        </div>

        <div className="flex-col flex justify-start">
          <div>
            <h3 className="font-light text-xs mt-2">Current</h3>
          </div>
          <div className="inline-flex mt-2">
            <h3 className="font-light text-xs">Submitted By:</h3>
            <h3 className="font-bold text-xs ml-2">Kaylax Yu</h3>
          </div>
          <div className="inline-flex mt-2">
            <h3 className="font-light text-xs">Modify</h3>
            <h3 className="font-bold text-xs ml-2">Clause 1 Sub-Clause A</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResoCard
