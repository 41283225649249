import React from "react"
import PropTypes from "prop-types"

export default function Basic({ onChange, placeholder, value }) {
  return (
    <input
      className="block w-full px-4 py-2 my-2 leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none"
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  )
}

Basic.defaultProps = {
  placeholder: "",
}

Basic.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
}
