import gql from "graphql-tag"

export const FRAGMENT_CONFERENCE_TIMESTAMP_INFO = gql`
  fragment ConferenceTimeStampInfo on Conference {
    startTime
    endTime
  }
`

export const FRAGMENT_USER_BASIC_INFO = gql`
  fragment UserBasicInfo on User {
    firstName
    lastName
    email
    status
  }
`

export const FRAGMENT_COMMITTEE_OPTIONS_INFO = gql`
  fragment CommitteeOptionsInfo on Committee {
    options
  }
`

export const FRAGMENT_COMMITTEE_BASIC_INFO = gql`
  fragment CommitteeBasicInfo on Committee {
    name
    type
    status
  }
`

export const FRAGMENT_ROLLCALLS_INFO = gql`
  fragment RollCallsInfo on RollCall {
    time
    attendee
    committee {
      _id
      name
    }
  }
`

export const FRAGMENT_ORGANIZATION_BASIC_INFO = gql`
  fragment OrganizationBasicInfo on Organization {
    name
    status
  }
`

export const FRAGMENT_ROLE_BASIC_INFO = gql`
  fragment RoleBasicInfo on Role {
    role
    detail
    status
  }
`

export const FRAGMENT_ROLE_TARGET_INFO = gql`
  fragment RoleTargetInfo on Role {
    roleTarget {
      __typename
      ... on Committee {
        _id
        name
      }
      ... on Conference {
        _id
        name
      }
      ... on Organization {
        _id
        name
      }
    }
  }
`

export const FRAGMENT_NOTE_BASIC_INFO = gql`
  fragment NoteBasicInfo on Note {
    content
  }
`

export const FRAGMENT_VOTE_BASIC_INFO = gql`
  fragment VoteBasicInfo on Vote {
    result
    topic
    mode
    link
    noAbstain
  }
`

export const FRAGMENT_NOTE_BLOCK_INFO = gql`
  fragment NoteBlockInfo on Note {
    blocked
    blockedReason
  }
`

export const FRAGMENT_NOTE_TIMESTAMP_INFO = gql`
  fragment NoteTimeStampInfo on Note {
    sendTime
    readTime
  }
`

export const FRAGMENT_VOTE_TIMESTAMP_INFO = gql`
  fragment VoteTimeStampInfo on Vote {
    startTime
    endTime
  }
`

export const FRAGMENT_ANNOUNCEMENT_INFO = gql`
  fragment AnnouncementInfo on Announcement {
    content
    postTime
  }
`

export const FRAGMENT_RESO_SHARE_INFO = gql`
  fragment ResoShareInfo on Resolution {
    isPublic
    googleDriveURL
  }
`

export const FRAGMENT_RESO_BASIC_INFO = gql`
  fragment ResoBasicInfo on Resolution {
    topic
    number
    resolutionStatus: status
  }
`

export const FRAGMENT_AMENDMENT_BASIC_INFO = gql`
  fragment AmendmentBasicInfo on Amendment {
    clause
    subClause
    subSubClause
    content
    type
    amendmentStatus: status
    isPublic
  }
`

export const FRAGMENT_CONFERENCE_BASIC_INFO = gql`
  fragment ConferenceBasicInfo on Conference {
    name
    logoSrc
    status
  }
`

export const FRAGMENT_OWNER_BASIC_INFO = gql`
  fragment OwnerBasicInfo on Role {
    owner {
      _id
      ...UserBasicInfo
      organization {
        _id
        ...OrganizationBasicInfo
      }
    }
  }
  ${FRAGMENT_USER_BASIC_INFO}
  ${FRAGMENT_ORGANIZATION_BASIC_INFO}
`

export const FRAGMENT_NOTE_SENDER_INFO = gql`
  fragment NoteSenderInfo on Note {
    sender {
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const FRAGMENT_NOTE_RECEIVER_INFO = gql`
  fragment NoteReceiverInfo on Note {
    receiver {
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const FRAGMENT_NOTE_REVIEWER_INFO = gql`
  fragment NoteReviewerInfo on Note {
    reviewer {
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const FRAGMENT_RESO_SUBMITTERS_INFO = gql`
  fragment ResoSubmittersInfo on Resolution {
    submitters {
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const FRAGMENT_AMENDMENT_SUBMITTER_INFO = gql`
  fragment AmendmentSubmitterInfo on Amendment {
    submitter {
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const FRAGMENT_ROLES_BASIC_INFO = gql`
  fragment RolesBasicInfo on User {
    roles {
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const FRAGMENT_ROLES_TARGET_INFO = gql`
  fragment RolesTargetInfo on User {
    roles {
      _id
      ...RoleTargetInfo
    }
  }
  ${FRAGMENT_ROLE_TARGET_INFO}
`
