/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable global-require */
import React, { useState } from "react"
import ReactCountryFlag from "react-country-flag"
import { Transition } from "@tailwindui/react"
import PropTypes from "prop-types"
import Modal from "./index"
import { roleCode } from "../../../constant"
import CountryOverwrite from "../../Miscellaneous/CountryOverwrite"

const { getData, getName, getCode } = require("country-list")

function countryOptionGenerator(country) {
  return (
    <option key={country.code} value={country.code}>
      {country.name}
    </option>
  )
}

function countryListParser(rawData, setFunc, settings) {
  const countryList = rawData.split(/\r\n|\r|\n/)
  const allCountryList = getData()
  const invalidCountryList = []
  const validCountryList = []
  const validCountryCode = []
  let found = false
  const stringSimilarity = require("string-similarity")

  if (rawData) {
    countryList.forEach((country) => {
      if (country) {
        if (getCode(country.charAt(0).toUpperCase() + country.slice(1))) {
          validCountryList.push(country)
          validCountryCode.push(
            getCode(country.charAt(0).toUpperCase() + country.slice(1))
          )
        } else {
          for (let i = 0; i < allCountryList.length; i += 1) {
            if (
              allCountryList[i].name
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(country.toLowerCase().replace(/\s/g, "")) ||
              stringSimilarity.compareTwoStrings(
                allCountryList[i].name.toLowerCase(),
                country.toLowerCase()
              ) > 0.9
            ) {
              validCountryList.push(country)
              validCountryCode.push(allCountryList[i].code)
              found = true
              break
            }
          }
          if (!found) {
            invalidCountryList.push(country)
          }
          found = false
        }
      }
    })
  }

  setFunc({
    ...settings,
    importedCountryList: {
      validCountryList,
      validCountryCode,
      invalidCountryList,
    },
  })
}

function AddRoleModal(props) {
  const [role, setRole] = useState("")
  const [settings, setSettings] = useState({
    importedCountryList: {
      validCountryList: [],
      validCountryCode: [],
      invalidCountryList: [],
    },
    addMethod: "",
  })
  const [delegate, setDelegate] = useState({})
  const [admin, setAdmin] = useState({})
  const [chair, setChair] = useState({})
  const allCountryList = getData().sort((a, b) => (a.name > b.name ? 1 : -1))

  const close = () => {
    setRole("")
    setDelegate({})
    setChair({})
    setAdmin({})
    setSettings({
      importedCountryList: {
        validCountryList: [],
        validCountryCode: [],
        invalidCountryList: [],
      },
      addMethod: "",
    })
    props.close()
  }

  return (
    <Modal
      close={close}
      isShown={props.isShown}
      onSubmit={() => {
        if (role.trim().length === 0) {
          return
        }

        const returnData = {
          type: settings.addMethod,
          isCustom: settings.delegationType === "custom",
          role,
          delegates: {
            code: settings.importedCountryList.validCountryCode,
            displayName: settings.importedCountryList.validCountryList,
          },
          delegate,
          admin,
          chair,
        }

        props.onSubmit(returnData).then(() => {
          setRole("")
          setDelegate({})
          setSettings({
            importedCountryList: {
              validCountryList: [],
              invalidCountryList: [],
              validCountryNum: 0,
            },
          })
          props.close()
        })
      }}
    >
      <div className="flex flex-wrap mb-6 -mx-3">
        <CountryOverwrite />
        <div className="w-full px-3">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="grid-rop"
          >
            Role
          </label>
          <select
            className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-rop"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">-</option>
            <option value={roleCode.Delegate}>Delegate</option>
            <option value={roleCode.Chair}>Chair</option>
            <option value={roleCode.Admin}>Admin</option>
          </select>
        </div>
      </div>

      {/* Add Delegate Section */}

      <Transition
        show={role === roleCode.Delegate}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="grid-rop"
              >
                How would you like to add your delegation(s)?
              </label>
              <div className="flex flex-row">
                <div className="text-center">
                  <button
                    type="button"
                    className={`bg-transparent hover:bg-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded m-2 "
                                                ${
                                                  settings.addMethod ===
                                                  "individual"
                                                    ? "text-white bg-blue-500 "
                                                    : "text-blue-700"
                                                }`}
                    onClick={() =>
                      setSettings({ ...settings, addMethod: "individual" })
                    }
                  >
                    Individual
                  </button>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className={`bg-transparent hover:bg-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded m-2 "
                                                ${
                                                  settings.addMethod === "list"
                                                    ? "text-white bg-blue-500 "
                                                    : "text-blue-700"
                                                }`}
                    onClick={() =>
                      setSettings({ ...settings, addMethod: "list" })
                    }
                  >
                    Import By List
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        {/* Add Delegate Individual Section */}

        <Transition show={settings.addMethod === "individual"}>
          <div className="flex flex-wrap my-6 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="grid-delegation"
              >
                Delegation
              </label>
              <Transition show={!settings.delegationType}>
                <select
                  className="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-delegation"
                  value={delegate.code}
                  onChange={(e) =>
                    setDelegate({
                      ...delegate,
                      code: e.target.value,
                      delegation: getName(e.target.value),
                    })
                  }
                  required
                >
                  <option key={0} value="">
                    -
                  </option>
                  {allCountryList.map(countryOptionGenerator)}
                </select>
                <p
                  className="text-xs text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setSettings({ ...settings, delegationType: "custom" })
                  }
                >
                  Cannot find your delegation?
                </p>
              </Transition>
              <Transition show={settings.delegationType === "custom"}>
                <input
                  className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-display-delegation"
                  type="text"
                  placeholder="Custom Delegation"
                  onChange={(e) =>
                    setDelegate({ ...delegate, delegation: e.target.value })
                  }
                  required
                />
                <p
                  className="text-xs text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setSettings({ ...settings, delegationType: "" })
                  }
                >
                  Switch back to country list
                </p>
              </Transition>
            </div>

            <Transition show={!settings.delegationType}>
              <div className="w-full px-3 pt-6">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-display-delegation"
                >
                  Custom Display Name (optional)
                </label>
                <div className="flex flex-row">
                  <input
                    className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-display-delegation"
                    type="text"
                    placeholder={getName(delegate.code ? delegate.code : "US")}
                    onChange={(e) =>
                      setDelegate({
                        ...delegate,
                        customDelegation: e.target.value,
                      })
                    }
                  />
                  <div className="px-4">
                    <ReactCountryFlag
                      countryCode={delegate.code ? delegate.code : "US"}
                      svg
                      style={{
                        width: "4em",
                        height: "4em",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Transition>

            <Transition show={settings.delegationType === "custom"}>
              <div className="w-full px-3 pt-6">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="grid-display-delegation"
                >
                  Flag Icon URL
                </label>
                <div className="flex flex-row">
                  <input
                    className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-display-delegation"
                    type="text"
                    placeholder="https://flag-icon.png"
                    onChange={(e) =>
                      setDelegate({
                        ...delegate,
                        customFlagSrc: e.target.value,
                      })
                    }
                  />
                  <div className="px-3 py-4 mb-3">
                    <img
                      alt="Not Found"
                      width="40"
                      height="40"
                      src={
                        delegate.customFlagSrc
                          ? delegate.customFlagSrc
                          : "https://munative-asia-01.s3-ap-southeast-1.amazonaws.com/MunativeLogo.png"
                      }
                    />
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </Transition>

        {/* Add Delegate By List Section */}

        <Transition show={settings.addMethod === "list"}>
          <div className="flex flex-row my-6 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="grid-countryList"
              >
                Paste your country list here:
              </label>
              <textarea
                cols="30"
                rows="10"
                className="border rounded resize-none focus:outline-none focus:shadow-outline"
                id="grid-countryList"
                placeholder="Brazil&#x0a;Canada&#x0a;China&#x0a;Egypt&#x0a;Japan&#x0a;...."
                onChange={(e) =>
                  countryListParser(e.target.value, setSettings, settings)
                }
                required
              />
              <p className="text-xs text-blue-500">
                Read Country:{" "}
                {settings.importedCountryList.validCountryList.length +
                  settings.importedCountryList.invalidCountryList.length}
              </p>
              <p className="text-xs text-blue-500">
                Identified Country:{" "}
                {settings.importedCountryList.validCountryList.length}
              </p>
            </div>
            <div className="w-full px-3">
              <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                System cannot identify:
              </label>
              <div className="h-64 overflow-auto">
                {settings.importedCountryList.invalidCountryList.map(
                  (country) => {
                    return (
                      <div className="max-w-sm my-1 overflow-hidden border border-gray-200 rounded">
                        <div className="px-6 pt-4 pb-2">
                          <span className="text-base text-gray-700">
                            {country}
                          </span>
                          <div className="flex flex-row">
                            <input
                              className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-display-delegation"
                              type="text"
                              placeholder="Flag Icon URL"
                              onChange={(e) =>
                                setSettings({
                                  ...settings,
                                  customFlagSrc: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </div>
        </Transition>
      </Transition>

      <Transition
        show={role === roleCode.Chair}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 py-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-chair-first-name"
            >
              First Name
            </label>
            <input
              className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-chair-first-name"
              value={chair.firstName}
              type="text"
              placeholder="John"
              onChange={(e) =>
                setChair({ ...chair, firstName: e.target.value })
              }
            />
          </div>
          <div className="w-full px-3 py-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-chair-last-name"
            >
              Last Name
            </label>
            <input
              className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-chair-last-name"
              value={chair.lastName}
              type="text"
              placeholder="Doe"
              onChange={(e) => setChair({ ...chair, lastName: e.target.value })}
            />
          </div>
          <div className="w-full px-3 py-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-chair-email"
            >
              Email
            </label>
            <input
              className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-chair-email"
              value={chair.email}
              type="text"
              placeholder="jdoe@munative.com"
              onChange={(e) => setChair({ ...chair, email: e.target.value })}
              required
            />
          </div>
        </div>
      </Transition>

      <Transition
        show={role === roleCode.Admin}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3 py-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="grid-chair-first-name"
            >
              Admin Name
            </label>
            <input
              className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-chair-first-name"
              value={admin.name}
              type="text"
              placeholder="John Doe"
              onChange={(e) => setAdmin({ ...admin, name: e.target.value })}
              required
            />
          </div>
        </div>
      </Transition>
    </Modal>
  )
}

AddRoleModal.defaultProps = {
  isShown: false,
}

AddRoleModal.propTypes = {
  close: PropTypes.func.isRequired,
  isShown: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

export default AddRoleModal
