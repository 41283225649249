import { shape, array, string } from "prop-types"

export const committeeType = shape({
  name: string,
  status: string,
  type: string,
  __typename: string,
  _id: string,
})

export const conferenceType = shape({
  committees: array,
  name: string,
  endTime: string,
  startTime: string,
  logoSrc: string,
  organization: shape({
    __typename: string,
    _id: string,
  }),
  status: string,
  _id: string,
})

export const roleDetailType = shape({
  detail: shape({
    loginCode: string,
  }),
  representative: shape({
    code: string,
    name: string,
  }),
  role: string,
  status: string,
  __typename: string,
  _id: string,
})
