import React from "react"
import EditIcon from "../Icons/Edit"

function CommitteeOption(props) {
  return (
    <div className="flex justify-between mb-4 text-xs font-bold text-white">
      <div className="self-center inline-block hover:text-gray-800">
        {props.committee ? props.committee.name : ""}
      </div>
      
      <div
        className="inline-block ml-4 hover:text-blue-600"
        onClick={props.onClick}
      >
        <EditIcon color={props.iconColor} hoverColor={props.hoverIconColor} />
      </div>
    </div>
  )
}

export default CommitteeOption
