/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

function AddOption({ isClose, iconColor, hoverIconColor, onClick, type }) {
  const { t } = useTranslation()

  const renderSwitch = (input) => {
    switch (input) {
      case "Conference":
        return t("dashboard.organizations.addConference")
      case "Committee":
        return t("dashboard.conferences.addCommittee")
      case "Role":
        return t("dashboard.committees.addRole")
      default:
        return ""
    }
  }

  return isClose ? (
    <div className="flex content-center mb-4 ml-4 text-xs">
      <a
        href="#"
        className={`inline-block fill-current ${iconColor} hover:${hoverIconColor}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="w-6 h-6"
        >
          <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16z" />
        </svg>
      </a>
    </div>
  ) : (
    <div
      className={`text-xs flex items-center mb-4 hover:${hoverIconColor} ${iconColor}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <a href="\#" className="inline-block fill-current">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="w-4 h-4"
        >
          <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16z" />
        </svg>
      </a>
      <div className="inline-block ml-2">{renderSwitch(type)}</div>
    </div>
  )
}

AddOption.defaultProps = {
  isClose: false,
}

AddOption.propTypes = {
  isClose: PropTypes.bool,
  iconColor: PropTypes.string.isRequired,
  hoverIconColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default AddOption
