import React from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import Email from "./Email"
import Code from "./Code"
import Mixed from "./Mixed"

const Login = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/login/email"
          render={(props) => (
            <>
              <Email tailwind="hidden md:flex" />
              <Mixed
                history={props.history}
                tailwind="flex md:hidden"
                index={0}
              />
            </>
          )}
        />

        <Route
          path="/login/code"
          render={(props) => (
            <>
              <Code tailwind="hidden md:flex" />
              <Mixed
                history={props.history}
                tailwind="flex md:hidden"
                index={1}
              />
            </>
          )}
        />
        {/* <Route path="/login/code">
          <Code tailwind="hidden md:flex" />
          <Mixed history= tailwind="flex md:hidden" index={1} />
        </Route> */}
        <Redirect from="/login" to="/login/email" />
      </Switch>
    </Router>
  )
}

export default Login
